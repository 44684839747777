import { useAuthContext, useContactDialogContext } from '@/contexts';
import { usePatients } from '@/hooks';
import { useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { InputTextarea } from 'primereact/inputtextarea';
import { motion } from 'framer-motion';
import { BigButton } from '@/components/button';
import { Calendar } from 'primereact/calendar';
import styles from './dialogModal.module.css';
import { DialogModalFormType } from './dialogModaltFormSchema';
import { DateTime } from 'luxon';

export function DialogModalFormAppointments() {
  const [inputHeight, setInputHeight] = useState('auto');

  const { registerContactCMO } = usePatients();
  const { patient } = useAuthContext();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { confirmPage, setConfirmPage } = useContactDialogContext();
  const [datesRange, setDatesRange] = useState<Date | Date[] | undefined>(undefined);

  const { control, formState, handleSubmit, setError, setValue } =
    useFormContext<DialogModalFormType>();

  const handleInput = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      e.target.style.height = 'auto';
      e.target.style.height = `${e.target.scrollHeight}px`;

      setInputHeight(`${e.target.scrollHeight}px`);
      setValue('message', e.target.value);

      if (e.target.offsetParent) {
        const parent = e.target.offsetParent as HTMLElement;
        const scrollDown = parent.scrollHeight - parent.clientHeight;
        parent.scrollTop = scrollDown;
      }
    },
    [setValue]
  );

  const onSubmit = useCallback(
    async (form: DialogModalFormType) => {
      if (!setDatesRange) {
        setError('topic', {
          type: 'manual',
          message: 'Date is required',
        });
        return;
      }
      if (form.message === '') {
        setError('message', {
          type: 'manual',
          message: 'Message is required',
        });
        return;
      }

      if (!patient?.id || !patient?.cmoEmail) return;

      const dates = Array.isArray(datesRange)
        ? datesRange.map((date) => DateTime.fromJSDate(date).toFormat('MMM dd')).join(', ')
        : DateTime.fromJSDate(datesRange as Date).toFormat('MMM dd');

      try {
        await registerContactCMO(
          patient?.id,
          patient?.cmoEmail,
          form.topic + ' - ' + dates,
          form.message
        );
        setValue('message', '');
        setInputHeight('auto');
        setConfirmPage(true);
      } catch (error: any) {
        setError('message', {
          type: 'manual',
          message: error?.response?.data?.message || error.message,
        });
        return;
      }
    },
    [
      patient?.id,
      patient?.cmoEmail,
      setError,
      registerContactCMO,
      setValue,
      setConfirmPage,
      datesRange,
    ]
  );

  return (
    <form
      className='font-medium relative'
      onSubmit={handleSubmit(onSubmit)}
      style={{ height: isMobile ? 'calc(100% - 6.5rem)' : 'calc(430px - 4rem)' }}
    >
      <div className='space-y-2 flex flex-col'>
        <label className='text-sm' htmlFor='date'>
          Desired date(s)
        </label>
        <Calendar
          selectionMode='multiple'
          readOnlyInput
          placeholder='You may select multiple options'
          inputId='date'
          aria-label='date'
          aria-controls='date'
          minDate={new Date()}
          value={datesRange}
          dateFormat='M dd'
          touchUI={isMobile ? true : false}
          onChange={(e) => {
            if (Array.isArray(e.value) && e.value.length <= 10) {
              setDatesRange(e.value);
            }
          }}
          className={styles.calendar}
        />
      </div>

      <Controller
        control={control}
        name='message'
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <div className='space-y-2 flex flex-col mt-8 mb-auto'>
            <label className='text-sm' htmlFor='message'>
              Details
            </label>
            <InputTextarea
              {...field}
              maxLength={400}
              className='bg-kelp-dark text-white border-none min-h-[100px] resize-none overflow-hidden'
              placeholder={'Please tell us how we can help'}
              style={{ height: inputHeight }}
              onInput={handleInput}
            />
            {fieldState.error?.message && (
              <span className='p-error text-sm'>{fieldState.error.message}</span>
            )}
          </div>
        )}
      />
      <motion.div
        style={{
          opacity: !formState.isValid || confirmPage ? 0 : 1,
          pointerEvents: formState.isValid ? 'auto' : 'none',
          transition: 'opacity 1.5s',
        }}
        transition={{ duration: 1.5 }}
        className='md:mt-24 md:sticky absolute bottom-0 md:bottom-4 w-full'
      >
        <BigButton variant='white' className='py-5 px-4'>
          {formState.isSubmitting || formState.isLoading ? (
            <i className='pi pi-spinner animate-spin text-kelp'></i>
          ) : (
            'Send to your care team'
          )}
        </BigButton>
      </motion.div>
    </form>
  );
}
