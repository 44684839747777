import { useApplicationContext, useAuthContext } from '@/contexts';
import { useEffect, useMemo } from 'react';
import { StoneCard } from '../card';
import { CareTeamProviderList } from '../careTeamProviders';

const DOCTORS_TAGS = [
  'Leadership',
  'Internal Medicine',
  'Cardiology',
  'Neurology',
  `Women's Health`,
  'Integrative Health',
  'Performance & Movement',
  'Nutrition',
  'Urgent & Home Care',
  'Dermatology',
  'Geriatrics',
  'Pediatrics',
  'Endocrinology',
];

export function AllTeam() {
  const { allDoctors, getAllDoctors, allDoctorsGroupedByTags } = useApplicationContext();
  const { patient } = useAuthContext();

  useEffect(() => {
    function loadInitialData() {
      if (!allDoctors) {
        getAllDoctors();
      }
    }
    loadInitialData();
  }, [allDoctors, getAllDoctors]);

  const providersList = useMemo(() => {
    const list = [];

    if (!allDoctorsGroupedByTags || allDoctorsGroupedByTags.size === 0) {
      return [];
    }

    for (const tag of DOCTORS_TAGS) {
      if (allDoctorsGroupedByTags?.has(tag)) {
        list.push({
          speciality: tag,
          providers: allDoctorsGroupedByTags
            .get(tag)!
            .filter(
              (provider) => provider.athenaProvider !== patient?.primaryProvider?.id.toString()
            )
            .map((provider) => ({
              name: provider.name,
              imageURL: provider.photo,
              profession: provider.degree,
              title: provider.title,
              locations: provider.location,
              doctor: provider,
            }))
            .sort((a, b) => (a.name.trim() > b.name.trim() ? 1 : -1)),
        });
      }
    }

    return list;
  }, [allDoctorsGroupedByTags, patient?.primaryProvider?.id]);

  return (
    <div className='pt-9 space-y-5'>
      <StoneCard text='A world-class team of physicians delivering proactive, preventive, personalized care' />
      <div className='pb-12'>
        <CareTeamProviderList paginationLimit={20} data={providersList} />
      </div>
    </div>
  );
}
