import { DialogModalFormAppointments, Disclaimer, PageTitle } from '@/components';
import { AppointmentsCard } from '@/components/appointmentsCard';
import { useApplicationContext, useContactDialogContext, usePageContext } from '@/contexts';
import { DateHelper } from '@/helper';
import { DateTime } from 'luxon';
import { useEffect } from 'react';

export function PreviousAppointmentsPage() {
  const { setPageTitle } = usePageContext();
  const { previousAppointments, getPreviousAppointments } = useApplicationContext();
  const { setButtonTitle, setContent, setContactDialogTitle, resetContactDialog } =
    useContactDialogContext();

  useEffect(() => {
    setPageTitle('Past');
  }, [setPageTitle]);

  useEffect(() => {
    getPreviousAppointments();
  }, [getPreviousAppointments]);

  useEffect(() => {
    if (previousAppointments && previousAppointments.length > 0) return;
    setButtonTitle('Request appointment');
    setContent(<DialogModalFormAppointments />);
    setContactDialogTitle('Request appointment');

    return () => {
      resetContactDialog();
    };
  }, [setButtonTitle, setContent, setContactDialogTitle, resetContactDialog, previousAppointments]);

  if (previousAppointments?.length === 0) {
    return (
      <Disclaimer text='It would be our pleasure to assist you with scheduling an appointment.' />
    );
  }

  return (
    <div className='w-full flex-1 flex flex-col gap-16 md:gap-[6.25rem]'>
      <PageTitle
        title={
          <>
            Your past <br /> appointments
          </>
        }
      />
      <AppointmentsCard
        appointments={previousAppointments?.map((appointment) => ({
          id: appointment.appointmentId,
          appointmentId: appointment.appointmentId,
          start: DateHelper.formatDateToDisplay(DateTime.fromISO(appointment.date)),
          time: DateTime.fromISO(appointment.date).toFormat('hh:mm a'),
          providerName: appointment.providerName,
          title: appointment.type,
          hasEncounter: appointment.encounterState
            ? appointment.encounterState === 'CLOSED'
            : false,
          encounterId: appointment.encounterId,
          hasLetter: appointment.hasLetter,
        }))}
      />
    </div>
  );
}
