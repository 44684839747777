import { cn, DisplayCard } from '@lib-atria/ui-toolkit';
import { AnimatePresence } from 'framer-motion';
import { useCallback, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { CopyToClipboard, DialogMobile } from '../_common';
import { TextLayout } from '../text';
import { CustomTooltip } from '../tooltip';
import { AppointmentsCardWrapper } from './';

type HomeVisitCardProps = {
  location: {
    street: string;
    complement?: string;
    city: string;
    phoneNumber: string;
    notes: {
      description: string;
    };
    directionMaps: string;
  };
};

export function AppointmentsCardLocation({
  location: { street, complement, city, phoneNumber, notes, directionMaps },
}: HomeVisitCardProps) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  return (
    <DisplayCard>
      <AppointmentsCardWrapper title={`Atria ${city}`}>
        <div className='flex flex-col items-center text-center'>
          <CopyToClipboard copyText={`${street}${complement && `, ${complement}`} `}>
            <span className={TextLayout.body1}>
              {street}
              {complement && `, ${complement}`}
            </span>
          </CopyToClipboard>
          <CopyToClipboard copyText={phoneNumber.replace(/\./g, ' ')}>
            <span className={TextLayout.body1}>{phoneNumber}</span>
          </CopyToClipboard>
        </div>
        <div className={cn(TextLayout.body1, 'flex flex-row md:gap-5 mt-3 text-forest-100')}>
          {isMobile ? (
            <span
              className='underline-offset-2 underline cursor-pointer not-italic'
              onClick={handleOpenDialog}
            >
              Parking notes
            </span>
          ) : (
            <CustomTooltip
              content={
                <div className='p-6 rounded-lg bg-experimental-forest text-stone flex space-x-4 items-center'>
                  <p className={TextLayout.body1}>{notes.description}</p>
                </div>
              }
            >
              <span className='underline-offset-2 underline cursor-pointer not-italic'>
                Parking notes
              </span>
            </CustomTooltip>
          )}

          <a
            className='underline-offset-2 underline cursor-pointer not-italic'
            href={directionMaps}
            target='_blank'
            rel='noreferrer'
          >
            Directions
          </a>
        </div>
      </AppointmentsCardWrapper>

      <AnimatePresence>
        {isDialogOpen && (
          <DialogMobile
            item={
              <div className='flex space-x-5 items-center pb-6'>
                <p>{notes.description}</p>
              </div>
            }
            handleOnClose={() => {
              setIsDialogOpen(false);
            }}
          />
        )}
      </AnimatePresence>
    </DisplayCard>
  );
}
