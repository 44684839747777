import { Tooltip } from 'primereact/tooltip';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClinicalDocumentsIcon, CustomTable } from '..';

import { AppointmentCard, CustomTableHeader } from '@/@types';
import { useAuthContext, useContactDialogContext } from '@/contexts';
import { LetterHelper } from '@/helper';
import { useAppointments } from '@/hooks';

const tableHeaders: CustomTableHeader[] = [
  { key: 'titleHtml', label: 'Title', sortKey: 'title', width: 400 },
  { key: 'providerName', label: 'Clinician', width: 250 },
  { key: 'start', label: 'Date', width: 280 },
  { key: 'time', label: 'Time', width: 180 },
];

type AppointmentsCardProps = {
  appointments?: AppointmentCard[];
};

export function AppointmentsCard({ appointments }: AppointmentsCardProps) {
  const navigate = useNavigate();
  const { patient } = useAuthContext();
  const { letter, findLetterByPatientIdAndEncounterIdSchema, clearLetter } = useAppointments();
  const { setContactDialogVisibility, setContactDialogTopic } = useContactDialogContext();

  const handleOnItemClick = useCallback(
    (item: AppointmentCard) => {
      navigate(`/appointments/${item.id}/encounter/${item.encounterId}/letter`);
    },
    [navigate]
  );

  const handleShowContactCareTeamDialog = useCallback(
    (item: AppointmentCard) => {
      setContactDialogTopic(`Question about ${item.title}`);
      setContactDialogVisibility(true);
    },
    [setContactDialogVisibility, setContactDialogTopic]
  );

  const handleEncounterLetterDownload = useCallback(
    async (item: AppointmentCard) => {
      if (patient?.id) {
        findLetterByPatientIdAndEncounterIdSchema(item.id, patient.id, item.encounterId!);
      }
    },
    [findLetterByPatientIdAndEncounterIdSchema, patient]
  );

  useEffect(() => {
    if (letter) {
      LetterHelper.createAndSavePdfWithLetter(letter);

      clearLetter();
    }
  }, [clearLetter, letter]);

  return (
    <CustomTable
      sortable={['titleHtml', 'providerName', 'start', 'time']}
      headers={tableHeaders}
      items={
        appointments?.map((appointment) => ({
          ...appointment,
          titleHtml: (
            <div className='flex gap-2'>
              <span>{appointment.title}</span>
              <span className='h-[21px] flex items-center gap-1'>
                {appointment.hasLetter && (
                  <>
                    <Tooltip target='.letter-link-tooltip' className='text-xs rounded-lg' />
                    <i
                      data-pr-tooltip='Open appointment letter'
                      data-pr-position='right'
                      className='letter-link-tooltip'
                    >
                      <ClinicalDocumentsIcon />
                    </i>
                  </>
                )}
              </span>
            </div>
          ),
          ...(appointment.hasLetter && {
            onClick: () => handleOnItemClick(appointment),
          }),
          actions: {
            more: [
              ...(appointment.hasLetter
                ? [
                    {
                      label: 'View',
                      onClick: () => handleOnItemClick(appointment),
                    },
                    {
                      label: 'Download',
                      onClick: () => handleEncounterLetterDownload(appointment),
                    },
                  ]
                : []),
              {
                label: 'Ask a question',
                onClick: () => handleShowContactCareTeamDialog(appointment),
              },
            ],
          },
        })) || []
      }
    />
  );
}
