import { DateTime } from 'luxon';

import { Auth } from '@/@types';
import { useContactDialogContext } from '@/contexts';
import { useCallback } from 'react';
import { BigButton, DialogModalFormAppointments, withPermission } from '..';
import { BasicCard } from './basicCard';
import { DateHelper } from '@/helper/dateHelper';

type Props = {
  title: string;
  appointment?: {
    type?: string;
    providerId?: number;
    providerName?: string;
    start?: string;
  };
};

export function AppointmentCard({ title, appointment }: Props) {
  const { setContent, setContactDialogTitle, setContactDialogVisibility } =
    useContactDialogContext();

  const handleOpenContactCareButton = useCallback(() => {
    setContent(<DialogModalFormAppointments />);
    setContactDialogTitle('Request appointment');
    setContactDialogVisibility(true);
  }, [setContactDialogTitle, setContactDialogVisibility, setContent]);

  const ContactCareButton = withPermission(
    BigButton,
    Auth.Permissions.UserPermissions.CONTACT_CARE_TEAM
  );

  return (
    <BasicCard className='h-full shadow-none bg-[#eeece5] rounded-xl px-5'>
      <p className='block font-medium leading-8 text-kelp mb-2'>{title}</p>
      {appointment ? (
        <>
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-0 items-center justify-between'>
            <div className='flex-1 flex flex-col items-start justify-center my-2'>
              <h2 className='text-2xl font-medium leading-8 text-kelp my-2'>{appointment.type}</h2>
              <p className='leading-[0.875rem] text-kelp'>{appointment.providerName}</p>
            </div>
            <div className='flex flex-col md:text-center text-kelp leading-[0.875rem]'>
              <p className='text-lg font-medium'>
                {DateHelper.formatDateToDisplay(DateTime.fromISO(appointment.start!))}
              </p>
              <p className='text-md'>{DateTime.fromISO(appointment.start!).toFormat('hh:mm a')}</p>
            </div>
          </div>
        </>
      ) : (
        <>
          <p className='text-gray-700 py-2'>Need to schedule an appointment?</p>

          <ContactCareButton onClick={handleOpenContactCareButton} className='block mt-4'>
            Contact care team
          </ContactCareButton>
        </>
      )}
    </BasicCard>
  );
}
