import { Button } from 'primereact/button';
import { useCallback, useMemo, useState } from 'react';

import { Patient } from '@/@types';
import { useLoaderContext, useToastContext } from '@/contexts';
import { TimerHelper } from '@/helper';
import { admViewRelations, selfKey, usePatients, userPermissionsList } from '@/hooks';
import { AxiosError, HttpStatusCode } from 'axios';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Loading } from '../loading';
import {
  PatientsRegistrationFormType,
  PatientsRegistrationFormSchemaResolver,
} from './patientsRegistrationFormSchema';
import { SearchPatientByName } from '../searchPatientByName';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { env } from '@/constants';

export const PatientRegistrationForm = () => {
  const { control, resetField, reset, getValues, handleSubmit } =
    useForm<PatientsRegistrationFormType>({
      resolver: PatientsRegistrationFormSchemaResolver,
    });

  const { fields, append, update, remove } = useFieldArray({ control, name: 'users' });

  const [patientData, setPatientData] = useState<Patient.Type | undefined>();
  const [resetPatientSearch, setResetPatientSearch] = useState<boolean>(false);
  const [validMRN, setValidMRN] = useState<boolean>();
  const [typingMRN, setTypingMRN] = useState<boolean>();
  const [successRegistration, setSuccessRegistration] = useState<boolean>();
  const { register } = usePatients();
  const { toast } = useToastContext();
  const { startLoader, stopLoader } = useLoaderContext();

  type severity = 'success' | 'info' | 'warn' | 'error' | undefined;
  const showMessage = useCallback(
    (severity: severity, summary: string, detail: string) =>
      toast?.current.show({
        severity,
        summary,
        detail,
        life: 2500,
      }),
    [toast]
  );

  const permissions = useMemo(() => {
    return userPermissionsList.map((p) => ({ value: p.id, label: p.description }));
  }, []);

  const maxAccountsByTime = useMemo(() => 5, []);

  const resetForm = useCallback(() => {
    resetField('users');
    setPatientData(undefined);
    setValidMRN(undefined);
  }, [resetField]);

  const resetComponent = useCallback(() => {
    setSuccessRegistration(undefined);
    reset();
    setValidMRN(undefined);
    setResetPatientSearch(!resetPatientSearch);
    setPatientData(undefined);
  }, [resetPatientSearch, reset]);

  const changePatient = useCallback(
    (data?: Patient.Type) => {
      resetForm();
      setPatientData(data);
    },
    [resetForm]
  );

  const isValidPatientId = useCallback(
    (patientId: number) => patientData?.id === patientId,
    [patientData]
  );

  const checkPatientId = useCallback(() => {
    const patientId = Number(getValues('users.0.patientId'));
    if (!patientData || !patientId) return;

    if (!isValidPatientId(patientId)) {
      setValidMRN(false);
      setTypingMRN(false);
      return;
    }

    setValidMRN(true);
    update(0, {
      patientId: patientData?.id.toString() || '',
      emailConfirmation1: patientData.email || '',
      emailConfirmation2: '',
      relation: selfKey,
      permissions: permissions.map((x) => x.value),
    });

    setTypingMRN(false);
  }, [patientData, isValidPatientId, getValues, update, setValidMRN, permissions]);

  const onSubmit = useCallback(
    async (form: PatientsRegistrationFormType) => {
      try {
        startLoader();
        const patientId = Number(form.users[0].patientId);
        if (!isValidPatientId(patientId)) {
          showMessage('error', 'Error', 'The Patient ID does not match');
          return;
        }

        const patients = form.users.map((user) => ({
          id: patientId,
          email: user.emailConfirmation2,
          relation: user.relation,
          permissions: user.permissions,
        }));
        await register(patients);
        setSuccessRegistration(true);
      } catch (error: any) {
        if (error instanceof AxiosError && error.response?.status === HttpStatusCode.Conflict) {
          showMessage(
            'error',
            'Error',
            `${form.users[0].emailConfirmation2} has already been registered.`
          );
        } else {
          showMessage('error', 'Error', 'Registration failed');
        }
      } finally {
        stopLoader();
      }
    },
    [showMessage, register, isValidPatientId, startLoader, stopLoader]
  );

  const userRelationsOptions = useMemo(
    () => Object.entries(admViewRelations).map(([key, val]) => ({ label: val, value: key })),
    []
  );

  const renderEmailConfirmation = useCallback(
    (userData: Patient.Type | undefined, index: number) => {
      const isMain = index === 0;
      return (
        <div key={index} className='mt-4'>
          {!isMain && (
            <>
              <span className='flex justify-end cursor-pointer' onClick={() => remove(index)}>
                X
              </span>
              <div className='flex flex-col mt-5'>
                <label htmlFor={`${index}.relation`} className='text-kelp'>
                  Who is this account for?
                </label>
                <div>
                  <Controller
                    control={control}
                    name={`users.${index}.relation`}
                    render={({ field, fieldState }) => (
                      <>
                        <Dropdown
                          name={'relation'}
                          options={userRelationsOptions}
                          onChange={(e) => field.onChange(e.target.value)}
                          className='flex'
                          value={field.value || ''}
                        />
                        {fieldState.error?.message && (
                          <span className='p-error text-sm'>{fieldState.error.message}</span>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
              <div className='flex flex-col mt-5'>
                <label htmlFor={`${index}.permissions`} className='text-kelp'>
                  Choose the permissions
                </label>
                <p className='flex flex-col pt-1 pb-2 text-xs'>
                  This account will have only these permissions access for this patient.
                </p>
                <div>
                  <Controller
                    control={control}
                    name={`users.${index}.permissions`}
                    render={({ field, fieldState }) => (
                      <>
                        <MultiSelect
                          name={'permissions'}
                          options={permissions}
                          onChange={(e) => field.onChange(e.target.value)}
                          value={field.value}
                          className='flex'
                        />
                        {fieldState.error?.message && (
                          <span className='p-error text-sm'>{fieldState.error.message}</span>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
            </>
          )}
          <div className='flex flex-col mt-5'>
            <label htmlFor='emailConfirmation1' className='text-kelp'>
              What email address should be used to log in to this account?
            </label>
            {isMain && (
              <p className='flex flex-col pt-1 pb-2 text-xs'>
                This email is from the member&apos;s profile in Athena. You can change this email
                and register this member with another email address if needed.
              </p>
            )}
          </div>
          <div>
            <Controller
              control={control}
              name={`users.${index}.emailConfirmation1`}
              render={({ field, fieldState }) => (
                <>
                  <InputText
                    name='emailConfirmation1'
                    onChange={(e) => field.onChange(e.target.value)}
                    value={field.value || ''}
                    placeholder='Email'
                    className='w-full'
                    disabled={!userData}
                  />
                  {fieldState.error?.message && (
                    <span className='p-error text-sm'>{fieldState.error.message}</span>
                  )}
                </>
              )}
            />
          </div>
          <div className='mt-3'>
            <Controller
              control={control}
              name={`users.${index}.emailConfirmation2`}
              render={({ field, fieldState }) => (
                <>
                  <div>
                    <InputText
                      id='emailConfirmation2'
                      name='emailConfirmation2'
                      onChange={(e) => field.onChange(e.target.value)}
                      onPaste={(e) => e.preventDefault()}
                      value={field.value || ''}
                      placeholder='Confirm email'
                      className='w-full'
                      disabled={!userData}
                    />
                    {fieldState.error?.message && (
                      <span className='p-error text-sm'>{fieldState.error.message}</span>
                    )}
                  </div>
                </>
              )}
            />
          </div>
        </div>
      );
    },
    [control, remove, permissions, userRelationsOptions]
  );

  if (successRegistration) {
    return (
      <div className='flex flex-col mt-2'>
        <h1 className='font-display text-experimental-forest text-center text-4xl'>Success!</h1>
        <div className='text-kelp text-center text-sm flex flex-col mt-4'>
          An email has been sent to {getValues('users.0.emailConfirmation2')}
        </div>
        <div className='flex flex-col mt-6'>
          <Button label='Create another' size='small' type='button' onClick={resetComponent} />
        </div>
      </div>
    );
  }

  return (
    <div className='flex flex-col items-start w-full'>
      <div className='flex flex-col w-full max-w-xl'>
        <SearchPatientByName searchBy='lastName' onSelect={changePatient} />
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col w-full'>
          <div>
            {patientData && (
              <div className='mt-4'>
                <Controller
                  control={control}
                  name='users.0.patientId'
                  render={({ field, fieldState }) => (
                    <>
                      <div className='flex flex-col mt-5'>
                        <label htmlFor='patientId' className='text-kelp'>
                          Confirm their MRN ID
                        </label>
                      </div>

                      <div>
                        <InputText
                          id='patientId'
                          name='patientId'
                          placeholder='MRN ID'
                          className='w-full'
                          onChange={(e) => {
                            setTypingMRN(true);
                            field.onChange(e.target.value);
                            TimerHelper.debounce(checkPatientId, 1000)();
                          }}
                          value={field.value || ''}
                        />
                      </div>
                      {!validMRN && validMRN !== undefined && (
                        <span className='p-error text-sm'>
                          The MRN ID and patient name does not match. Please double check both
                          fields!
                        </span>
                      )}
                      {fieldState.error?.message && (
                        <span className='p-error text-sm'>{fieldState.error.message}</span>
                      )}
                    </>
                  )}
                />
              </div>
            )}
          </div>
          <div className={classNames({ invisible: !typingMRN, hidden: validMRN })}>
            <Loading label='' className='mt-4' />
          </div>
          <div className={classNames({ hidden: !validMRN })}>
            {fields.map((_, idx) => renderEmailConfirmation(patientData, idx))}

            {env.APP_FEATURE_FLAGS?.IS_TO_ENABLE_FAMILY_ASSOCIATION && (
              <div className='flex flex-col mt-3'>
                <button
                  className={'flex font-medium outline-none transition-all w-full'}
                  onClick={() =>
                    append({
                      patientId: patientData?.id.toString() || '',
                      emailConfirmation1: '',
                      emailConfirmation2: '',
                      relation: '',
                      permissions: permissions.map((x) => x.value),
                    })
                  }
                >
                  <div className='flex flex-1 justify-center items-center before:bg-[#607663] before:inline-block before:mx-2 before:my-10 before:w-24 before:h-0.5 after:bg-[#607663] after:inline-block after:mx-2 after:my-10 after:w-24 after:h-0.5'>
                    <i className='material-icons-round text-base pr-5'>add</i>
                    <span>Add family or assistant</span>
                  </div>
                </button>
                {fields.length > maxAccountsByTime && (
                  <div className='flex flex-col mt-4 text-xs p-error items-center'>
                    You cannot create/associate more than {maxAccountsByTime} accounts by time.
                  </div>
                )}
              </div>
            )}

            <div className='flex flex-col mt-5'>
              <Button
                label='Create member account'
                size='small'
                type='submit'
                disabled={!patientData}
              />
            </div>
            <div className='flex flex-col mt-4 text-xs'>
              FYI: An email will be sent to the member at the email address above with instructions
              to create a password. Their login will be the email address provided.
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
