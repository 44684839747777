import { AnimationHelper, cn } from '@lib-atria/ui-toolkit';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';

import { Auth } from '@/@types';
import { useApplicationContext, useAuthContext } from '@/contexts';
import { patientsService } from '@/services';
import { StaggerChild, StaggerChildren } from '../animation';
import { MaterialIcons } from '../icons';
import { TextLayout } from '../text';
import { SwitchAccountsCard } from './switchAccountsCard';
import { AuthHelper } from '@/helper';
import { Relation, selfKey, userRelations } from '@/hooks';

type Patient = Omit<Auth.Context.Patient, 'cmoEmail'> & { relation: Relation };

type Props = {
  onSelect?: VoidFunction;
};

export function SwitchAccounts({ onSelect }: Props) {
  const { reset } = useApplicationContext();
  const { getAccessToken, isSwitchAccountsVisible, setIsSwitchAccountsVisible, signInPatient } =
    useAuthContext();
  const [patients, setPatients] = useState<Array<Patient>>([]);

  const handleSelectPatient = useCallback(
    (patient: Omit<Auth.Context.Patient, 'cmoEmail'>) => {
      reset();
      signInPatient(patient);
      setIsSwitchAccountsVisible(false);
      if (onSelect) onSelect();
    },
    [onSelect, reset, setIsSwitchAccountsVisible, signInPatient]
  );

  useEffect(() => {
    async function init() {
      const { patients: patientsToken } = await getAccessToken();
      const patientIds = patientsToken.map(({ patientId }) => patientId);
      const patientsAthena = await patientsService.findAllPatients({ patientId: patientIds });
      const patientsList = patientsAthena.data
        .map((patient) => ({
          ...patient,
          relation: patientsToken.find(({ patientId }) => patientId == patient.id)!.relation,
          permissions: patientsToken
            .find(({ patientId }) => patientId == patient.id)!
            .permissions?.map(AuthHelper.convertToUserPermissionEnum),
        }))
        .sort((a) => (a.relation === selfKey ? -1 : 1));
      setPatients(patientsList);
    }
    init();
  }, [getAccessToken]);

  useEffect(() => {
    const handleClose = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsSwitchAccountsVisible(false);
      }
    };
    document.addEventListener('keydown', handleClose);
    return () => document.removeEventListener('keydown', handleClose);
  }, [setIsSwitchAccountsVisible]);

  return (
    <AnimatePresence initial={false}>
      {isSwitchAccountsVisible && (
        <motion.div
          initial='hidden'
          animate='show'
          exit='hidden'
          variants={{
            show: { y: '0%', transition: { duration: 0.6, ease: AnimationHelper.ease } },
            hidden: { y: '-100%', transition: { duration: 0.6, ease: AnimationHelper.ease } },
          }}
          className='bg-noise-forest-100 w-screen h-screen absolute top-0 left-0 p-2 z-50'
        >
          <div className='w-full h-full rounded-lg border border-product-sage border-opacity-50 p-1'>
            <div className='w-full h-full atria-scrollbar overflow-y-auto flex flex-col gap-20 items-center justify-start rounded-[5px] border border-product-sage border-opacity-30'>
              <div className='w-full lg:p-6 relative flex items-center justify-between'>
                <div className='lg:absolute lg:top-1/2 lg:-translate-y-1/2 lg:left-0 flex items-center justify-center'>
                  <button
                    onClick={() => setIsSwitchAccountsVisible(false)}
                    className='w-[64px] h-[64px] flex items-center justify-center transition-colors hover:bg-white/5 active:bg-white/10 rounded-full'
                  >
                    <MaterialIcons name='close' className='text-[24px] text-white' />
                  </button>
                </div>
                <div className='w-full flex items-center justify-center'>
                  <p
                    className={cn(
                      TextLayout.callout3,
                      'text-product-sand-100 text-center whitespace-nowrap'
                    )}
                  >
                    Switch accounts
                  </p>
                </div>
              </div>
              <div className='flex-1 w-full flex flex-col lg:flex-row items-start lg:items-center justify-start lg:justify-center'>
                <StaggerChildren
                  duration={0.6}
                  delay={0.9}
                  className={cn(
                    'flex-1 w-full px-6 pb-6 gap-4',
                    patients.length >= 4
                      ? 'grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 items-stretch justify-center'
                      : 'flex flex-col lg:flex-row items-start justify-start lg:items-center lg:justify-center'
                  )}
                >
                  {patients.map((patient) => (
                    <StaggerChild key={patient.id} className='w-full'>
                      <SwitchAccountsCard
                        onClick={() => handleSelectPatient(patient)}
                        firstName={patient.firstNameUsed || patient.firstName}
                        lastName={patient.lastName}
                        relation={userRelations[patient.relation]}
                      />
                    </StaggerChild>
                  ))}
                </StaggerChildren>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
