/* eslint-disable no-console */
import { EventAnalytics, EventAnalyticsAdmin } from './eventAnalytics';

export class ConsoleEventAnalytics implements EventAnalytics {
  identify(uniqueId: string): void {
    this.log({ event: 'identify', data: { uniqueId } });
  }

  track(eventName: string, properties: { [key: string]: any }): void {
    this.log({ event: 'track', data: { eventName, properties } });
  }

  reset(): void {
    this.log({ event: 'reset' });
  }

  trackPageView(pageView: string): void {
    this.log({ event: 'trackPageView', data: { pageView } });
  }

  setAdmin(admin: EventAnalyticsAdmin): void {
    this.log({ event: 'SetAdmin', data: { admin } });
  }

  private log(data: { [key: string]: any }): void {
    console.log('[EventAnalytics]', JSON.stringify(data, null, 2));
  }
}
