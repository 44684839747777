import { ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { Loader } from '@/components';
import { ApplicationProvider } from './applicationContext';
import { AuthZeroProvider } from './authZeroContext';
import { AuthProvider } from './authContext';
import { ContactDialogProvider } from './contactDialogContext';
import { LoaderProvider } from './loaderContext';
import { PageProvider } from './pageContext';
import { ToastProvider } from './toastContext';

export * from './applicationContext';
export * from './authContext';
export * from './contactDialogContext';
export * from './loaderContext';
export * from './pageContext';
export * from './toastContext';

export function AppContexts({ children }: { children: ReactNode }) {
  return (
    <LoaderProvider>
      <BrowserRouter>
        <AuthZeroProvider>
          <AuthProvider>
            <ToastProvider>
              <PageProvider>
                <ContactDialogProvider>
                  <ApplicationProvider>
                    <Loader />
                    {children}
                  </ApplicationProvider>
                </ContactDialogProvider>
              </PageProvider>
            </ToastProvider>
          </AuthProvider>
        </AuthZeroProvider>
      </BrowserRouter>
    </LoaderProvider>
  );
}
