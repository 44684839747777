import { Variants, cubicBezier, motion } from 'framer-motion';
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Outlet, useLocation } from 'react-router-dom';

import { DialogModal } from '..';
import { EnvironmentBadge } from './environmentBadge';
import { Navbar } from './navbar';

type Props = {
  isVisible: boolean;
  onSidebarToggle: Dispatch<SetStateAction<boolean>>;
  isContactButtonVisible?: boolean;
};

export function MainContent({ isVisible, onSidebarToggle, isContactButtonVisible }: Props) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { pathname } = useLocation();

  const animationsVariant: Variants = useMemo(
    () =>
      isMobile
        ? {
            margin: { marginLeft: '0px' },
            noMargin: { marginLeft: '0px' },
          }
        : {
            margin: { marginLeft: '282px' },
            noMargin: { marginLeft: '0px' },
          },
    [isMobile]
  );

  useEffect(() => {
    const main = document.getElementById('main');
    if (main) {
      main.scrollIntoView();
    }
  }, [pathname]);

  return (
    <motion.main
      id='main'
      initial={{ marginLeft: '282px' }}
      variants={animationsVariant}
      transition={{ duration: 0.5, ease: cubicBezier(0.65, 0, 0.35, 1) }}
      animate={isVisible ? 'margin' : 'noMargin'}
      style={{
        clipPath: 'inset(0 0 0 0)',
      }}
      className='py-4 px-8 pb-20 min-h-screen'
    >
      <Navbar
        onSidebarToggle={() => onSidebarToggle((prev) => !prev)}
        isContactButtonVisible={isContactButtonVisible}
      />
      <EnvironmentBadge />
      <motion.div
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 1.2, ease: [0.6, -0.05, 0.01, 0.99] }}
        className='w-full max-w-[2000px] m-auto flex flex-col px-0 md:px-16'
      >
        <Outlet />
      </motion.div>

      <DialogModal />
    </motion.main>
  );
}
