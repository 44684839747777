import { useCallback } from 'react';
import createFastContext from './fastContext';
type ContactDialogContent = {
  contactDialogMessagePlaceholder: string;
  isContactDialogBodyRequired: boolean;
  contactDialogTopic: string | string[];
  contactDialogNote: string;
  isContactDialogVisible: boolean;
  buttonTitle: string;
  confirmPage: boolean;
  content: any;
  contactDialogTitle: string;
  useTopicSelect: boolean;
  options: string[];
  topicLabel: string;
};

const contactDialogDefaultContent: ContactDialogContent = {
  contactDialogMessagePlaceholder: 'Tell us more...',
  isContactDialogBodyRequired: true,
  contactDialogTopic: '',
  contactDialogNote: '',
  isContactDialogVisible: false,
  buttonTitle: 'Contact care team',
  confirmPage: false,
  content: null,
  contactDialogTitle: 'How can we help?',
  useTopicSelect: false,
  options: [''],
  topicLabel: 'Topic',
};

const { Provider, useStore } = createFastContext({
  ...contactDialogDefaultContent,
});

export const ContactDialogProvider = Provider;

export const useContactDialogContext = () => {
  const [state, setState] = useStore((s) => s);

  const setContactDialogVisibility = useCallback(
    (isContactDialogVisible: boolean) => {
      setState({ isContactDialogVisible });
    },
    [setState]
  );

  const setContactDialogTopic = useCallback(
    (contactDialogTopic: string | string[]) => {
      const topicArray = Array.isArray(contactDialogTopic)
        ? contactDialogTopic
        : [contactDialogTopic];

      setState({ contactDialogTopic: topicArray });
    },
    [setState]
  );

  const setContactDialogNote = useCallback(
    (contactDialogNote: string) => {
      setState({ contactDialogNote });
    },
    [setState]
  );

  const setContactDialogTitle = useCallback(
    (contactDialogTitle: string) => {
      setState({ contactDialogTitle });
    },
    [setState]
  );

  const setContactDialogMessagePlaceholder = useCallback(
    (contactDialogMessagePlaceholder: string) => {
      setState({ contactDialogMessagePlaceholder });
    },
    [setState]
  );

  const setIsContactDialogBodyRequired = useCallback(
    (isContactDialogBodyRequired: boolean) => {
      setState({ isContactDialogBodyRequired });
    },
    [setState]
  );

  const resetContactDialog = useCallback(() => {
    setState({
      ...contactDialogDefaultContent,
      contactDialogTitle: 'How can we help?',
    });
  }, [setState]);

  const setButtonTitle = useCallback(
    (buttonTitle: string) => {
      setState({ buttonTitle });
    },
    [setState]
  );
  const setContent = useCallback(
    (contentData: any) => {
      setState({ content: contentData });
    },
    [setState]
  );

  const setConfirmPage = useCallback(
    (confirmPage: boolean) => {
      setState({ confirmPage });
    },
    [setState]
  );

  const setUseTopicSelect = useCallback(
    (useTopicSelect: boolean) => {
      setState({ useTopicSelect });
    },
    [setState]
  );

  const setOptionsSelect = useCallback(
    (options: string[]) => {
      setState({ options });
    },
    [setState]
  );

  const setTopicLabel = useCallback(
    (topicLabel: string) => {
      setState({ topicLabel });
    },
    [setState]
  );

  return {
    ...state,
    setContactDialogVisibility,
    setContactDialogTopic,
    setContactDialogNote,
    setContactDialogTitle,
    setContactDialogMessagePlaceholder,
    setIsContactDialogBodyRequired,
    resetContactDialog,
    setButtonTitle,
    setConfirmPage,
    setContent,
    setUseTopicSelect,
    setOptionsSelect,
    setTopicLabel,
  };
};
