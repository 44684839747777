import { Button, cn } from '@lib-atria/ui-toolkit';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Auth } from '@/@types';
import { TextLayout } from '@/components';
import { useApplicationContext, useAuthContext } from '@/contexts';
import { patientsService } from '@/services';
import { Relation, selfKey } from '@/hooks';

type Patient = Omit<Auth.Context.Patient, 'cmoEmail'> & { relation: Relation };

export function SignInWithPatientsPage() {
  const navigate = useNavigate();
  const { signInPatient, getAccessToken } = useAuthContext();
  const { reset } = useApplicationContext();
  const [patients, setPatients] = useState<Array<Patient>>([]);

  const handleSelectPatient = useCallback(
    (patient: Omit<Auth.Context.Patient, 'cmoEmail'>) => {
      reset();
      signInPatient(patient);
    },
    [reset, signInPatient]
  );

  useEffect(() => {
    async function init() {
      const { hasMultiplePatients, patients: patientsToken } = await getAccessToken();
      if (!hasMultiplePatients) {
        navigate('/sign-in');
        return;
      }
      const patientIds = patientsToken.map(({ patientId }) => patientId);
      const patientsAthena = await patientsService.findAllPatients({ patientId: patientIds });
      const patientsList = patientsAthena.data
        .map((patient) => ({
          ...patient,
          relation: patientsToken.find(({ patientId }) => patientId == patient.id)!.relation,
        }))
        .sort((a) => (a.relation === selfKey ? -1 : 1));
      setPatients(patientsList);
    }
    init();
  }, [getAccessToken, navigate]);

  const handleLogout = useCallback(() => {
    navigate('/sign-out');
  }, [navigate]);

  return (
    <div className='w-full flex flex-col items-start gap-2 py-10 px-6'>
      <div>
        <h2 className={cn(TextLayout.callout2, 'text-product-forest-100')}>Patients</h2>
        <h2 className='text-sm text-product-forest-100'>
          Select the patient you want to access the records for
        </h2>
      </div>
      <div className='w-full'>
        <div className='w-full flex flex-col gap-2 my-6'>
          {patients.length ? (
            patients?.map((patient) => (
              <div
                key={patient.id}
                onClick={() => handleSelectPatient(patient)}
                className={cn(
                  'w-full flex flex-col divide-y divide-product-sand-300 hover:divide-product-sand-500 overflow-hidden rounded-xl cursor-pointer transition-colors border border-product-sand-300 bg-product-sand-100 hover:bg-product-forest-100 active:bg-product-forest-200 hover:border-product-forest-100 group',
                  patient.relation === selfKey && 'border-product-forest-100'
                )}
              >
                {patient.relation && (
                  <div
                    className={cn(
                      'min-w-[100px] px-4 flex items-center justify-start py-2',
                      patient.relation === selfKey && 'bg-product-forest-100'
                    )}
                  >
                    <p
                      className={cn(
                        TextLayout.body1,
                        'text-product-forest-100 group-hover:text-product-sand-100',
                        patient.relation === selfKey && 'text-product-sand-100'
                      )}
                    >
                      {patient.relation}
                    </p>
                  </div>
                )}
                <div className='flex-1 px-4 py-2'>
                  <p
                    className={cn(
                      TextLayout.body1,
                      'text-product-forest-100 group-hover:text-product-sand-100'
                    )}
                  >
                    {patient.patientName}
                  </p>
                  <p
                    className={cn(
                      TextLayout.body3,
                      'text-product-forest-100 group-hover:text-product-sand-100'
                    )}
                  >
                    {patient.dateOfBirth}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <div className='w-full py-4 flex'>
              <p className={cn(TextLayout.body3)}>Loading...</p>
            </div>
          )}
        </div>
      </div>
      <div className='-ml-3'>
        <Button variant='link' size='small' label='Logout' onClick={handleLogout} />
      </div>
    </div>
  );
}
