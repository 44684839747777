import { useCallback, useState } from 'react';

import { AppointmentEncounterSummary, AppointmentLetter } from '@/@types';
import { useLoaderContext } from '@/contexts';
import { AppointmentsService } from '@/services';

export function useAppointments() {
  const { isLoading, startLoader, stopLoader } = useLoaderContext();
  const [encounterSummary, setEncounterSummary] = useState<
    AppointmentEncounterSummary | undefined
  >();
  const [letter, setLetter] = useState<AppointmentLetter | undefined>();

  const findEncounterSummaryByAppointmentId = useCallback(
    async (appointmentId: number) => {
      try {
        startLoader({ isDefaultComponentVisible: false });
        const { data } =
          await AppointmentsService.findEncounterSummaryByAppointmentId(appointmentId);
        setEncounterSummary(data);
      } finally {
        stopLoader();
      }
    },
    [startLoader, stopLoader]
  );

  const findLetterByPatientIdAndEncounterIdSchema = useCallback(
    async (appointmentId: number, patientId: number, encounterId: number) => {
      try {
        startLoader();
        const { data } = await AppointmentsService.getLetterByPatientIdAndEncounterId(
          appointmentId,
          patientId,
          encounterId
        );
        setLetter(data);
      } finally {
        stopLoader();
      }
    },
    [startLoader, stopLoader]
  );

  const clearLetter = useCallback(() => {
    setLetter(undefined);
  }, []);

  return {
    isLoading,
    encounterSummary,
    letter,
    findEncounterSummaryByAppointmentId,
    findLetterByPatientIdAndEncounterIdSchema,
    clearLetter,
  };
}
