import { AtriaButtonVariants } from '@/components';
import { useCallback } from 'react';
import createFastContext from './fastContext';

type PageButtonActions = Partial<{
  buttons: {
    variant?: AtriaButtonVariants;
    icon?: string;
    label?: string;
    onClick: VoidFunction;
  }[];
}>;

type Context = {
  pageTitle: string;
  isPageTitleVisible: boolean;
  isBackButtonVisible: boolean;
  isSidebarVisible: boolean;
  actions?: PageButtonActions;
  backButtonLabel?: string;
};

const initialState = {
  pageTitle: 'Atria Records',
  isPageTitleVisible: true,
  isBackButtonVisible: false,
  isSidebarVisible: true,
  backButtonLabel: 'Back',
};

const { Provider, useStore } = createFastContext<Context>(initialState);

export const PageProvider = Provider;

export const usePageContext = () => {
  const [state, setState] = useStore((s) => s);

  const setPageTitle = useCallback(
    (pageTitle?: string, visible: boolean = true) => {
      if (!pageTitle) {
        setState({
          pageTitle: undefined,
          isPageTitleVisible: visible,
          isBackButtonVisible: false,
          actions: undefined,
        });
        document.title = 'Atria Records';
        return;
      }
      setState({
        pageTitle,
        isPageTitleVisible: visible,
        isBackButtonVisible: false,
        actions: undefined,
      });
      document.title = `Atria Records | ${pageTitle}`;
    },
    [setState]
  );

  const setIsBackButtonVisible = useCallback(
    (visiblity: boolean, label?: string) => {
      if (visiblity) {
        setState({
          isBackButtonVisible: visiblity,
          isPageTitleVisible: false,
          backButtonLabel: label || 'Back',
        });
      } else {
        setState({
          isBackButtonVisible: visiblity,
          isPageTitleVisible: true,
          backButtonLabel: label || 'Back',
        });
      }
    },
    [setState]
  );

  const setIsPageTitleVisible = useCallback(
    (visible: boolean) => {
      setState({ isPageTitleVisible: visible });
    },
    [setState]
  );

  const setActions = useCallback(
    (actions?: PageButtonActions) => {
      setState({ actions });
    },
    [setState]
  );

  const setSidebarVisibility = useCallback(
    (isSidebarVisible: boolean) => {
      setState({ isSidebarVisible });
    },
    [setState]
  );

  return {
    ...state,
    setPageTitle,
    setIsBackButtonVisible,
    setActions,
    setIsPageTitleVisible,
    setSidebarVisibility,
  };
};
