import { useCallback, useState } from 'react';

import { useAuthContext, useLoaderContext, useToastContext } from '@/contexts';
import { patientsService } from '@/services';

export function usePatientsChart() {
  const { toast } = useToastContext();
  const { startLoader, stopLoader } = useLoaderContext();
  const { patient } = useAuthContext();
  const [fileUrl, setFileUrl] = useState(null);

  const getDocumentId = useCallback(
    async (startDate: string, documentClass: string[]) => {
      startLoader();
      try {
        const response = await patientsService.getPatientDocumentId(
          patient!.id,
          startDate,
          documentClass
        );
        return response.data.documentId;
      } catch (error: any) {
        const message =
          error?.response?.data?.messages?.[0]?.message ||
          'No documents found, please try again later.';
        toast?.current.show({
          severity: 'error',
          summary: 'Error',
          detail: message,
          life: 2500,
        });
      } finally {
        stopLoader();
      }
    },
    [patient, startLoader, stopLoader, toast]
  );

  const getChartByDocumentId = useCallback(
    async (documentId: number) => {
      startLoader({ label: 'Please wait, this may take a few minutes.' });
      const response = await patientsService.getPatientChartByDocumentId(patient!.id, documentId);
      const file = new Blob([response.data], { type: 'application/pdf' });
      const reader: any = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setFileUrl(reader.result);
        stopLoader();
      };
    },
    [patient, startLoader, stopLoader]
  );

  return {
    patient,
    fileUrl,
    getDocumentId,
    getChartByDocumentId,
  };
}
