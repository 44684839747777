import { Stats } from '@lib-atria/ui-toolkit';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

type Users = Array<{
  lastLogin?: string;
  registrationStatus: string;
}>;

type PatientsCardProps = {
  users: Users;
  labels: {
    invited: string;
    registered: string;
    activeInLastMonth: string;
    activeInLastWeek: string;
  };
};

const getActiveCount = (users: Users, duration: { months?: number; weeks?: number }) => {
  const now = DateTime.now();
  const thresholdDate = duration.months
    ? now.minus({ months: duration.months }).toMillis()
    : now.minus({ weeks: duration.weeks }).toMillis();

  return users.filter((user) => {
    if (typeof user.lastLogin !== 'string') return false;

    const lastLoginTimestamp = DateTime.fromFormat(user.lastLogin, 'MM/dd/yyyy hh:mma').toMillis();
    return lastLoginTimestamp > thresholdDate;
  }).length;
};

export function PatientsCard({ users, labels }: PatientsCardProps) {
  const invitedMembers = useMemo(
    () => users.filter((user) => user.registrationStatus === 'invited').length,
    [users]
  );

  const registeredMembers = useMemo(
    () => users.filter((user) => user.registrationStatus === 'registered').length,
    [users]
  );

  const activeInLastMonth = useMemo(() => getActiveCount(users, { months: 1 }), [users]);

  const activeInLastWeek = useMemo(() => getActiveCount(users, { weeks: 1 }), [users]);

  return (
    <div className='grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-6 gap-y-2 md:pt-10 pt-5'>
      <Stats label={labels.invited} value={invitedMembers} />
      <Stats label={labels.registered} value={registeredMembers} />
      <Stats label={labels.activeInLastMonth} value={activeInLastMonth} />
      <Stats label={labels.activeInLastWeek} value={activeInLastWeek} />
    </div>
  );
}
