import { Relation } from '@/hooks';

export namespace Auth {
  export namespace Context {
    export type User = {
      id: string;
      name: string;
      email: string;
      isAdmin: boolean;
      permissions?: Permissions.UserPermissions[] & Permissions.AdmPermissions[];
    };

    export type Patient = {
      id: number;
      patientName: string;
      firstNameUsed?: string;
      firstName: string;
      lastName: string;
      email?: string;
      dateOfBirth: string;
      cmoEmail: string;
      primaryProvider: {
        id: number;
        firstName?: string;
        lastName: string;
        email?: string;
      };
      permissions?: Permissions.UserPermissions[];
    };

    export type GetAccessToken = {
      id: string;
      name: string;
      email: string;
      expiresIn: string;
      patientId: number;
      hasMultiplePatients: boolean;
      patients: Array<{
        patientId: number;
        relation: Relation;
        permissions?: number[];
      }>;
    };

    export type Type = {
      patient?: Auth.Context.Patient;
      setPatient: (patient: Auth.Context.Patient | undefined) => void;
      user: Auth.Context.User | undefined;
      setUser: (user: Auth.Context.User | undefined) => void;
      isAuthenticated: boolean;
      isAdmin: boolean;
      isStaffAdmin: boolean;
      setExpiresIn: (expiresIn: string | undefined) => void;
      expiresIn?: string;
      signInPatient: (patient: Omit<Auth.Context.Patient, 'cmoEmail'>) => void;
      hasMultiplePatients: boolean;
      setHasMultiplePatients: (hasMultiplePatients: boolean) => void;
      switchMember: () => void;
      loginWithRedirect: (options: { email: string }) => void;
      signOutUser: () => Promise<void>;
      getAccessToken: (code?: string) => Promise<Auth.Context.GetAccessToken>;
      signInAdmin: (patient: Omit<Auth.Context.Patient, 'cmoEmail'>) => void;
      isSwitchAccountsVisible: boolean;
      setIsSwitchAccountsVisible: (isSwitchAccountsVisible: boolean) => void;
    };
  }

  export namespace JWT {
    export type Payload = {
      id: string;
      userName: string;
      userEmail: string;
      patientId: number;
      patients: Array<{
        patientId: number;
        relation: Relation;
        permissions: Array<number>;
      }>;
      iss: string;
      sub: string;
      aud: Array<string>;
      iat: number;
      exp: number;
      scope: string;
      azp: string;
    };
  }

  export namespace GetTokenByOktaAccessToken {
    export type Response = {
      accessToken: string;
      permissions: Array<string>;
    };
  }

  export namespace DeleteAcessToken {
    export type Response = void;
  }

  export namespace Permissions {
    export enum UserPermissions {
      VIEW_APPOINTMENTS = '0',
      VIEW_MEDICATIONS = '1',
      VIEW_IMMUNIZATIONS = '2',
      VIEW_ENCOUNTER_LETTER = '3',
      VIEW_RESULTS = '4',
      VIEW_MY_CARE_TEAM = '5',
      CONTACT_CARE_TEAM = '6',
    }

    export enum AdmPermissions {
      EDIT_USER = 'EDIT_USER',
    }

    export const permissionDescriptions: Record<UserPermissions, string> = {
      [UserPermissions.VIEW_APPOINTMENTS]: 'VIEW_APPOINTMENTS',
      [UserPermissions.VIEW_MEDICATIONS]: 'VIEW_MEDICATIONS',
      [UserPermissions.VIEW_IMMUNIZATIONS]: 'VIEW_IMMUNIZATIONS',
      [UserPermissions.VIEW_ENCOUNTER_LETTER]: 'VIEW_ENCOUNTER_LETTER',
      [UserPermissions.VIEW_RESULTS]: 'VIEW_RESULTS',
      [UserPermissions.VIEW_MY_CARE_TEAM]: 'VIEW_MY_CARE_TEAM',
      [UserPermissions.CONTACT_CARE_TEAM]: 'CONTACT_CARE_TEAM',
    };
  }
}
