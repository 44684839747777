import { AppointmentEncounterSummary, AppointmentLetter } from '@/@types';
import { api } from '@/providers';

async function findEncounterSummaryByAppointmentId(appointmentId: number) {
  return api.get<AppointmentEncounterSummary>(`/api/v1/appointments/${appointmentId}/encounters`);
}

function getLetterByPatientIdAndEncounterId(
  appointmentId: number,
  patientId: number,
  encounterId: number
) {
  return api.get<AppointmentLetter>(
    `/api/v1/patients/${patientId}/appointments/${appointmentId}/encounters/${encounterId}/letter`
  );
}

export const AppointmentsService = {
  findEncounterSummaryByAppointmentId,
  getLetterByPatientIdAndEncounterId,
};
