import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { usePatientsChart } from '@/hooks';
import { ChartExportFormSchemaResolver, ChartExportFormType } from './chartExportFormSchema';
import { Button, Select, Multiselect, cn, TextLayout } from '@lib-atria/ui-toolkit';

export const ChartExportForm = () => {
  const { control, formState, handleSubmit } = useForm<ChartExportFormType>({
    resolver: ChartExportFormSchemaResolver,
  });
  const { getDocumentId } = usePatientsChart();
  const minDate = DateTime.now()
    .set({ year: 2022, month: 1, day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 })
    .toJSDate();

  const startDateOptions = useMemo(
    () => [
      {
        label: 'Previous 3 months',
        value: DateTime.now().minus({ month: 3 }).toISO(),
      },
      {
        label: 'Previous 6 months',
        value: DateTime.now().minus({ month: 6 }).toISO(),
      },
      {
        label: 'Previous 1 year',
        value: DateTime.now().minus({ year: 1 }).toISO(),
      },
      {
        label: 'All time',
        value: 'ALL_TIME',
      },
    ],
    []
  );
  const documentTypes = useMemo(
    () => [
      { label: 'Lab Result', value: 'LABRESULT' },
      { label: 'Imaging Result', value: 'IMAGINGRESULT' },
      { label: 'Letter', value: 'LETTER' },
    ],
    []
  );

  const onSubmit = async (form: ChartExportFormType) => {
    let date = form.startDate;
    if (form.startDate === 'ALL_TIME') {
      date = minDate.toISOString();
    }
    const startDate = DateTime.fromISO(date).toFormat('yyyy-MM-dd');
    const documentId = await getDocumentId(startDate, form.documentClass);
    if (documentId) {
      window.open(`/patient/chart/pdf/${documentId}`, '_blank', 'noreferrer');
    }
  };

  return (
    <div className='flex w-full md:w-12/12'>
      <form onSubmit={handleSubmit(onSubmit)} className='md:w-6/12 w-full'>
        <div className='mb-5'>
          <Controller
            control={control}
            name='startDate'
            render={({ field, fieldState }) => (
              <div className='flex flex-col gap-2'>
                <label
                  htmlFor='startDate'
                  className={cn(TextLayout.body1, 'text-kelp', {
                    'p-error': fieldState.error,
                  })}
                >
                  Timespan
                </label>
                <Select
                  options={startDateOptions}
                  placeholder='Select a start date'
                  variant='light'
                  size='large'
                  className={cn({
                    'p-invalid': !!fieldState.error,
                  })}
                  onChange={(e) => field.onChange(e)}
                  value={field.value}
                />
                {fieldState.error?.message && (
                  <span className='p-error text-sm'>{fieldState.error.message}</span>
                )}
              </div>
            )}
          />
        </div>
        <div className='mb-5'>
          <Controller
            control={control}
            name='documentClass'
            render={({ field, fieldState }) => (
              <div className='flex flex-col gap-2'>
                <label
                  htmlFor='documentClass'
                  className={cn(TextLayout.body1, 'text-kelp', {
                    'p-error': fieldState.error,
                  })}
                >
                  Document type
                </label>
                <Multiselect
                  options={documentTypes}
                  className={cn({
                    'p-invalid': !!fieldState.error,
                  })}
                  placeholder='Select document type'
                  onChange={(values: Array<string | number>) => field.onChange(values)}
                  value={field.value}
                  size='large'
                />
                {fieldState.error?.message && (
                  <span className='p-error text-sm'>{fieldState.error.message}</span>
                )}
              </div>
            )}
          />
        </div>
        <div className='mt-10 [&>button]:w-full'>
          <Button
            disabled={Object.values(formState.errors).length > 0}
            type='submit'
            label='Generate PDF'
            size='large'
          />
        </div>
      </form>
    </div>
  );
};
