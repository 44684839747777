import classNames from 'classnames';
import { useMemo } from 'react';

import { useAccordionContext } from './accordion';
import { AccordionChevron } from './accordionChevron';
import { AccordionItemTitle } from './accordionItemTitle';
import { AccordionContent } from './accordionContent';

type Props = {
  id: number;
  title: string;
  isLast?: boolean;
  children: React.ReactNode;
};

export function AccordionItem({ id, title, isLast = false, children }: Props) {
  const { selected: selectedIndex, open } = useAccordionContext();

  const isOpen = useMemo(() => selectedIndex === id, [id, selectedIndex]);

  return (
    <div className='w-full flex flex-col'>
      <div
        onClick={() => open(id)}
        className={classNames(
          'w-full px-5 md:px-8 py-4 flex flex-row items-center justify-between gap-12 cursor-pointer bg-white z-20',
          {
            'border-b border-product-sand-200': !isLast,
          }
        )}
      >
        <AccordionItemTitle>{title}</AccordionItemTitle>
        <AccordionChevron isOpen={isOpen} />
      </div>
      <AccordionContent isVisible={isOpen}>{children}</AccordionContent>
    </div>
  );
}
