import { cn } from '@lib-atria/ui-toolkit';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { TextLayout } from '../text';

type Props = {
  selected: boolean;
  onSelect: (date: string) => void;
  date: string;
};

export function AppointmentsCardDateSidebar({ selected, onSelect, date }: Props) {
  const handleOnSelect = () => {
    if (date) {
      onSelect(date);
    }
  };

  const formattedDate = useMemo(() => {
    if (!date) return '';
    const [onlyDate] = date.split('/');
    return DateTime.fromISO(onlyDate).toISO();
  }, [date]);

  return (
    <div
      className={cn(
        'py-3 px-6 rounded bg-white flex flex-col justify-center items-center cursor-pointer ',
        selected && 'bg-fern'
      )}
      onClick={handleOnSelect}
    >
      <span
        className={cn(
          'font-medium text-lg',
          selected && 'text-white',
          !selected && 'text-gray-400'
        )}
      >
        {DateTime.fromISO(formattedDate!).toFormat('LLL')}
      </span>
      <span
        className={cn(
          TextLayout.body1,
          'font-medium text-4xl leading-[30.04px] md:-tracking-[1px]',
          selected && 'text-white',
          !selected && 'text-product-sage '
        )}
      >
        {DateTime.fromISO(formattedDate!).toFormat('dd')}
      </span>
    </div>
  );
}
