import { useApplicationContext, useContactDialogContext } from '@/contexts';
import { cn, DateModule } from '@lib-atria/ui-toolkit';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  AppointmentsCardDateSidebar,
  AppointmentsCardFooter,
  AppointmentsCardSchedule,
} from '../appointmentsCard';
import { AtriaIcon } from '../icons';
import { DialogModalFormAppointments } from '../modal';
import { UpcomingAppointmentsLocation } from './upcommingAppointmentsLocation';

export function UpcommingAppointments() {
  const { upcomingAppointmentsExtended, getUpcomingAppointmentsExtended } = useApplicationContext();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [constraints, setConstraints] = useState({ left: 0, right: 0 });

  const [selectedDateIndex, setSelectedDateIndex] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const {
    isContactDialogVisible,
    setContactDialogVisibility,
    setContent,
    setContactDialogTitle,
    setContactDialogTopic,
  } = useContactDialogContext();
  useEffect(() => {
    getUpcomingAppointmentsExtended();
  }, [getUpcomingAppointmentsExtended]);

  const handleScheduleAppointment = useCallback(() => {
    setContent(<DialogModalFormAppointments />);
    setContactDialogTitle('Request appointment');
    setContactDialogVisibility(!isContactDialogVisible);
  }, [isContactDialogVisible, setContactDialogTitle, setContactDialogVisibility, setContent]);

  const handleAskQuestion = useCallback(() => {
    setContactDialogTopic('General question about appointments');
    setContactDialogVisibility(!isContactDialogVisible);
  }, [isContactDialogVisible, setContactDialogTopic, setContactDialogVisibility]);

  const handleOnDaySelected = useCallback((key: string, index: number) => {
    document.getElementById(key)?.scrollIntoView({
      behavior: 'smooth',
    });
    setSelectedDateIndex(index);
  }, []);

  useEffect(() => {
    if (containerRef.current && sidebarRef.current) {
      const containerWidth = containerRef.current.scrollWidth;
      const viewportWidth = sidebarRef.current.clientWidth;

      setConstraints({ left: -(viewportWidth - containerWidth + 60), right: 0 });
    }
  }, [upcomingAppointmentsExtended]);

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRefs.current.length === 0) return;

      const scrollPosition = window.scrollY;
      let newSelectedIndex = 0;

      // Loop through section refs to find the current section
      sectionRefs.current.forEach((section, index) => {
        if (section) {
          const offsetTop = section.offsetTop;
          const offsetBottom = offsetTop + section.offsetHeight;
          if (scrollPosition >= offsetTop - 50 && scrollPosition < offsetBottom - 50) {
            newSelectedIndex = index;
          }
        }
      });

      if (newSelectedIndex !== selectedDateIndex) {
        setSelectedDateIndex(newSelectedIndex);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [selectedDateIndex, sectionRefs]);

  return (
    <div
      ref={containerRef}
      className={cn(
        'w-full grid grid-cols-1 gap-5 md:gap-[79px] relative',
        'md:grid-cols-[1fr_93px]'
      )}
    >
      <div className='flex-1 w-full flex flex-col gap-0.5'>
        {upcomingAppointmentsExtended &&
          upcomingAppointmentsExtended.map((grouppedAppointments, index) => (
            <div key={grouppedAppointments.key} id={grouppedAppointments.key}>
              <div className='space-y-4 relative' ref={(el) => (sectionRefs.current[index] = el)}>
                <DateModule
                  date={grouppedAppointments.date}
                  endTime={grouppedAppointments.endDate}
                  onAskQuestion={handleAskQuestion}
                  startTime={grouppedAppointments.startDate}
                  onConfirm={() => handleAskQuestion}
                  confirmed={grouppedAppointments.confirmed}
                  threeDots={[
                    {
                      label: 'Cancel appointment',
                      onClick: () => {
                        console.log('clicked');
                      },
                    },
                    {
                      label: 'Reschedule appointment',
                      onClick: () => {
                        console.log('clicked');
                      },
                    },
                  ]}
                />
                <UpcomingAppointmentsLocation appointmentGroup={grouppedAppointments} />
                <AppointmentsCardSchedule
                  appointments={grouppedAppointments.appointments!}
                  doctor={grouppedAppointments.doctor}
                />
              </div>
              <AtriaIcon className='mx-0 w-full max-w-4xl my-12 h-8 ' fill='#CBC6BD' />
            </div>
          ))}

        <AppointmentsCardFooter
          text='Your care team is here to assist with your appointments. Please reach out at any time.'
          buttons={[
            { label: 'Schedule an appointment', onClick: handleScheduleAppointment },
            { label: 'Ask a question', onClick: handleAskQuestion },
          ]}
        />
      </div>

      {isMobile ? (
        <div
          ref={sidebarRef}
          className='z-10 fixed bottom-0 flex flex-row items-center justify-start bg-product-sand-200 p-4 -ml-8'
        >
          <motion.div
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            drag='x'
            className='flex w-full gap-6'
            dragConstraints={constraints}
          >
            {upcomingAppointmentsExtended &&
              upcomingAppointmentsExtended.map((appt, index) => {
                return (
                  <AppointmentsCardDateSidebar
                    key={appt.key}
                    date={appt.date}
                    onSelect={() => handleOnDaySelected(appt.key, index)}
                    selected={selectedDateIndex === index}
                  />
                );
              })}
          </motion.div>
        </div>
      ) : (
        <div className='-order-1 md:order-1 ' style={{ display: 'block', overflow: 'visible' }}>
          <div className='flex-row md:flex flex md:flex-col items-center justify-start gap-6 md:gap-[20px] border-t border-b md:border-none sticky top-[48px] pb-10'>
            {upcomingAppointmentsExtended &&
              upcomingAppointmentsExtended.map((appt, index) => {
                return (
                  <AppointmentsCardDateSidebar
                    key={appt.key}
                    date={appt.date}
                    onSelect={() => handleOnDaySelected(appt.key, index)}
                    selected={selectedDateIndex === index}
                  />
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
}
