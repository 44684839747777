import { useCallback, useState } from 'react';

import { User } from '@/@types';
import { useLoaderContext, useToastContext } from '@/contexts';
import { AttachmentHelper } from '@/helper';
import { UserService } from '@/services';
import { DateTime } from 'luxon';

export const userRelations = {
  assistant: 'Assistant',
  child: 'Child',
  external: 'External Provider',
  grandchild: 'Grandchild',
  grandparent: 'Grandparent',
  legal: 'Legal Guardian',
  parent: 'Parent',
  partner: 'Partner/Spouse',
  self: 'You',
};

export const admViewRelations = {
  ...userRelations,
  self: 'Self',
};

export const selfKey = 'self';

export type Relation = keyof typeof userRelations & 'unknown';

export const userPermissionsList = [
  {
    id: '0',
    name: 'VIEW_APPOINTMENTS',
    description: 'View patient appointments',
  },
  {
    id: '1',
    name: 'VIEW_MEDICATIONS',
    description: 'View patient medications',
  },
  {
    id: '2',
    name: 'VIEW_IMMUNIZATIONS',
    description: 'View patient immunizations',
  },
  {
    id: '3',
    name: 'VIEW_ENCOUNTER_LETTER',
    description: 'View and download the patient encounter letters',
  },
  {
    id: '4',
    name: 'VIEW_RESULTS',
    description: 'View patient results',
  },
  {
    id: '5',
    name: 'VIEW_MY_CARE_TEAM',
    description: 'View patient care team information',
  },
  {
    id: '6',
    name: 'CONTACT_CARE_TEAM',
    description: 'Contact patient care team',
  },
];

export function useUsers() {
  const { startLoader, stopLoader } = useLoaderContext();
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<User.FindAllUsers.Response>([]);
  const [user, setUser] = useState<User.FindUserById.Response | undefined>();
  const [permissions, setPermissions] = useState<User.FindAllPermissions.Response>([]);
  const { toast } = useToastContext();

  const findAllUsers = useCallback(
    async (params?: User.FindAllUsers.Params) => {
      try {
        startLoader();
        const { status, data } = await UserService.findAllUsers(params);
        if (status === 200) {
          setUsers(data);
        }
      } catch (error) {
        setUsers([]);
      } finally {
        stopLoader();
      }
    },
    [startLoader, stopLoader]
  );

  const findAllMembers = useCallback(
    () => findAllUsers({ 'patients.relation': selfKey }),
    [findAllUsers]
  );

  const findUserById = useCallback(async (patientId: string) => {
    const userResponse = await UserService.findUserById(patientId);
    if (!userResponse.data) return;
    const userData = {
      ...userResponse.data,
      createdAt: DateTime.fromISO(userResponse.data.createdAt).toFormat('M/d/yy'),
    };
    setUser(userData);
    return userData;
  }, []);

  const exportUsersCSVFile = useCallback(async (params?: User.ExportAllUsers.Params) => {
    try {
      setIsLoading(true);
      const binary = await UserService.exportUsersCSVFile(params);
      AttachmentHelper.download(binary.data, 'text/csv');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const exportMembersCSVFile = useCallback(
    (params?: User.ExportAllUsers.Params) => exportUsersCSVFile({ ...params, relation: selfKey }),
    [exportUsersCSVFile]
  );

  const resendInvitation = useCallback(
    async (userId: string) => {
      try {
        await UserService.resendInvitation(userId);
        setUsers((prev) =>
          prev.map((prevUser) => {
            if (prevUser.id === userId) {
              return {
                ...prevUser,
                lastResendInvite: DateTime.now().toFormat('MM/dd/yyyy hh:mma').toLowerCase(),
              };
            }
            return prevUser;
          })
        );
        toast?.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Invitation resent successfully',
          life: 2500,
        });
      } catch (error: any) {
        toast?.current.show({
          severity: 'error',
          summary: 'Error',
          detail: error?.response?.data?.message || 'Error resending invitation',
          life: 2500,
        });
      }
    },
    [toast]
  );

  const resendInvitations = useCallback(
    async (userIds: string[]) => {
      try {
        await Promise.all(userIds.map((userId) => UserService.resendInvitation(userId)));
        setUsers((prev) =>
          prev.map((prevUser) => {
            if (userIds.includes(prevUser.id)) {
              return {
                ...prevUser,
                lastResendInvite: DateTime.now().toFormat('MM/dd/yyyy hh:mma').toLowerCase(),
              };
            }
            return prevUser;
          })
        );
        toast?.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Invitation resent successfully',
          life: 2500,
        });
      } catch (error: any) {
        toast?.current.show({
          severity: 'error',
          summary: 'Error',
          detail: error?.response?.data?.message || 'Error resending invitation',
          life: 2500,
        });
      }
    },
    [toast]
  );
  const findAllPermissions = useCallback(async () => {
    try {
      const { data } = await UserService.findAllUsersPermissions();
      setPermissions(data);
      return data;
    } catch (error: any) {
      return [];
    }
  }, []);

  return {
    users,
    isLoading,
    findAllUsers,
    findAllMembers,
    user,
    findUserById,
    exportUsersCSVFile,
    exportMembersCSVFile,
    resendInvitation,
    resendInvitations,
    permissions,
    findAllPermissions,
    setUsers,
  };
}
