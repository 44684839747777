import { Button, cn, Sheet, TextLayout } from '@lib-atria/ui-toolkit';
import { Dropdown } from 'primereact/dropdown';
import { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MultiSelect } from 'primereact/multiselect';

import { User } from '@/@types';
import { Relation, selfKey, userPermissionsList, userRelations } from '@/hooks';
import { userEditSheetResolver, UserEditSheetType } from './userEditSheetSchema';
import { UserService } from '@/services';
import { useToastContext } from '@/contexts';

type UserItem = User.FindAllUsers.Response[0];

type Props = {
  isVisible: boolean;
  onClose: VoidFunction;
  user: UserItem;
  patientId: number;
  onEdit: (props: { userId: string; relation: Relation; permissions: Array<string> }) => void;
};

export function UserEditSheet({ isVisible, onClose, user, patientId, onEdit }: Props) {
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm<UserEditSheetType>({ resolver: userEditSheetResolver });
  const { toast } = useToastContext();

  const userRelationsOptions = useMemo(
    () =>
      Object.entries(userRelations)
        .filter(([key]) => key !== selfKey)
        .map(([key, val]) => ({ label: val, value: key })),
    []
  );

  const currentPatient = useMemo(
    () => user?.patients?.find((p) => p.patientId === patientId),
    [patientId, user?.patients]
  );

  const onSubmit = useCallback(
    (values: UserEditSheetType) => {
      const body = {
        appMetadata: {
          patients: user.patients.map((p) => {
            const patient = p.patientId === patientId;
            if (patient) {
              return {
                ...p,
                relation: values.relation as Relation,
                permissions: values.permissions,
              };
            }
            return { ...p, relation: p.relation as Relation };
          }),
        },
      };
      UserService.updateUserById(user.id, body)
        .then(() => {
          toast?.current.show({
            severity: 'success',
            summary: 'Updating user',
            detail: 'Patient successfully updated',
            life: 2500,
          });
          onEdit({
            userId: user.id,
            permissions: values.permissions,
            relation: values.relation as Relation,
          });
          onClose();
        })
        .catch((error) => {
          toast?.current.show({
            severity: 'error',
            summary: 'Updating user',
            detail: error.response.data?.message || 'An unexpected error occurred',
            life: 2500,
          });
        });
    },
    [onClose, onEdit, patientId, toast, user.id, user.patients]
  );

  useEffect(() => {
    if (currentPatient?.relation) {
      setValue('relation', currentPatient?.relation);
    }
    if (currentPatient?.permissions) {
      setValue('permissions', currentPatient?.permissions);
    }
  }, [currentPatient?.permissions, currentPatient?.relation, setValue]);

  return (
    <Sheet isVisible={isVisible} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className={cn(TextLayout.callout1, 'font-display text-product-forest-100 mb-4')}>
          {user?.name},
          <span className={cn(TextLayout.callout3, 'font-body  text-product-forest-100 p-2')}>
            {user?.email}
          </span>
        </p>

        <p className={cn(TextLayout.body3, 'text-product-forest-100 mb-4')}>Relation</p>
        <Controller
          control={control}
          name='relation'
          render={({ field }) => (
            <Dropdown
              {...field}
              value={field.value}
              options={userRelationsOptions}
              placeholder='Relation'
              className='w-full rounded-[42px] outline-none px-9 py-2 mb-4'
              disabled={currentPatient?.relation === selfKey}
            />
          )}
        />
        {errors.relation?.message && (
          <p className={cn(TextLayout.body3, 'text-rust mb-4')}>{errors.relation.message}</p>
        )}
        <p className={cn(TextLayout.body3, 'text-product-forest-100 mb-4')}>Permissions</p>
        <Controller
          control={control}
          name='permissions'
          render={({ field }) => (
            <div className='pb-4'>
              <MultiSelect
                multiple
                className='w-full rounded-[42px] outline-none px-9 py-2 hover:ring-0'
                options={userPermissionsList}
                placeholder='Select permissions'
                optionLabel='description'
                optionValue='id'
                filter
                {...field}
              />
            </div>
          )}
        />
        {errors.permissions?.message && (
          <p className={cn(TextLayout.body3, 'text-rust mb-4')}>{errors.permissions.message}</p>
        )}
        <div className='w-full py-4 flex justify-end pr-10'>
          <Button type='submit' label='Save' />
        </div>
      </form>
    </Sheet>
  );
}
