import { useAuthContext, useContactDialogContext } from '@/contexts';
import classNames from 'classnames';
import { AnimatePresence, PanInfo, cubicBezier, motion } from 'framer-motion';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import styles from './dialogModal.module.css';
import { ConfirmDialogModal } from './dialogModalConfirm';
import { FormDialogModal } from './dialogModalForm';
import { DialogModalHeader } from './dialogModalHeader';
import { DialogModalFormType } from './dialogModaltFormSchema';

export function DialogModal() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const {
    isContactDialogVisible,
    contactDialogTopic,
    contactDialogTitle,
    resetContactDialog,
    setContactDialogVisibility,
    setConfirmPage,
    confirmPage,
    contactDialogMessagePlaceholder,
    isContactDialogBodyRequired,
    content,
    contactDialogNote,
    useTopicSelect,
  } = useContactDialogContext();

  const { user } = useAuthContext();
  const { name } = user || {};
  const username = name?.split(' ')[0] || 'there';

  const methods = useForm<DialogModalFormType>();

  useEffect(() => {
    methods.setValue('topic', contactDialogTopic, { shouldValidate: true });
  }, [contactDialogTopic, methods, useTopicSelect]);

  const handleOnDialogClose = useCallback(
    (isVisible: boolean) => {
      methods.reset();
      resetContactDialog();
      setContactDialogVisibility(isVisible);
    },
    [methods, resetContactDialog, setContactDialogVisibility]
  );

  const handleDragEnd = (info: PanInfo) => {
    if (info.offset.y > 100 && isContactDialogVisible) {
      handleOnDialogClose(false);
      setConfirmPage(false);
    }
  };

  return (
    <AnimatePresence>
      {isContactDialogVisible && (
        <>
          {isMobile && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.3 } }}
              exit={{ opacity: 0, transition: { duration: 0.6 } }}
              className='fixed inset-0 bg-[#f8f6f6d9]/[.40] text-white w-full h-full'
              onClick={() => handleOnDialogClose(false)}
            />
          )}

          <motion.div
            initial={{ y: '100%' }}
            animate={{ y: 0, transition: { duration: 0.6, ease: cubicBezier(0.65, 0, 0.35, 1) } }}
            exit={{ y: '100%', transition: { duration: 0.3 } }}
            dragConstraints={{ top: 0 }}
            drag={isMobile ? 'y' : false}
            onDragEnd={(_event, info) => handleDragEnd(info)}
            className={classNames(
              'fixed md:bottom-2 bottom-0 md:right-5 right-0 bg-kelp z-30 overflow-x-hidden md:max-h-[496px] max-h-[80%] h-full md:max-w-[326px] text-white md:rounded-xl rounded-t-xl w-full font-body',
              styles.scroll
            )}
          >
            {isMobile && (
              <div className='flex items-center justify-center z-0'>
                <div
                  className={classNames(
                    'absolute -top-2 h-2 bg-kelp rounded-full w-10',
                    confirmPage && 'bg-cream'
                  )}
                />
              </div>
            )}
            {confirmPage && (
              <ConfirmDialogModal
                isVisibleConfirm={confirmPage}
                setIsVisibleModal={handleOnDialogClose}
                setIsVisibleConfirm={setConfirmPage}
                username={username}
              />
            )}
            <div className='px-6 pb-6 md:pb-4 h-full relative'>
              <DialogModalHeader
                className={styles.gradientSmooth}
                isVisible={isContactDialogVisible}
                setIsVisible={handleOnDialogClose}
                title={contactDialogTitle}
                note={contactDialogNote}
              />

              <FormProvider {...methods}>
                {content ? (
                  content
                ) : (
                  <FormDialogModal
                    messagePlaceholder={contactDialogMessagePlaceholder}
                    isBodyRequired={isContactDialogBodyRequired}
                  />
                )}
              </FormProvider>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}
