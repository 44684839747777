import { AppointmentSelectionType } from '@/@types';
import { DialogModalFormAppointments, PageTitle } from '@/components';
import { UpcommingAppointments } from '@/components/upcommingAppointments';
import { useContactDialogContext, usePageContext } from '@/contexts';
import { useCallback, useEffect, useState } from 'react';
import { DinamicSwitchContent } from './switchContent/dinamicSwitchContent';

export function AppointmentsPage() {
  const { setPageTitle, setActions } = usePageContext();
  const [selectedOption, setSelectedOption] = useState<string>(
    AppointmentSelectionType.UPCOMING.toString()
  );

  const {
    setContent,
    setContactDialogTitle,
    resetContactDialog,
    setContactDialogVisibility,
    setContactDialogTopic,
  } = useContactDialogContext();

  const handleOpenContactCareButton = useCallback(
    (visible: boolean) => {
      setContactDialogVisibility(visible);
      setContactDialogTitle('Request appointment');
      setContent(<DialogModalFormAppointments />);
    },
    [setContactDialogVisibility, setContactDialogTitle, setContent]
  );

  useEffect(() => {
    setPageTitle('Appointments');

    setActions({
      buttons: [
        {
          icon: 'add',
          label: 'Schedule an appointment',
          onClick: () => handleOpenContactCareButton(true),
        },
      ],
    });
    return () => {
      resetContactDialog();
      setActions({ buttons: [] });
    };
  }, [
    handleOpenContactCareButton,
    resetContactDialog,
    setActions,
    setContactDialogTopic,
    setPageTitle,
  ]);

  useEffect(() => {
    setContactDialogTopic('General question about appointments');
  }, [setContactDialogTopic, setContent]);

  const handleSelectedOption = useCallback((option: string) => {
    setSelectedOption(option);
  }, []);

  return (
    <>
      <PageTitle title='Your Atria journey' />
      <DinamicSwitchContent
        onSelect={handleSelectedOption}
        options={[
          {
            label: 'Upcomming',
            value: AppointmentSelectionType.UPCOMING.toString(),
            component: UpcommingAppointments,
          },
          {
            label: 'Past',
            value: AppointmentSelectionType.PAST.toString(),
            component: PreviousAppointmentsPage,
          },
        ]}
        selectedOption={selectedOption}
      />
    </>
  );
}

const PreviousAppointmentsPage = () => {
  return <div>Previous appointments</div>;
};
