import jsPDF from 'jspdf';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { renderToStaticMarkup } from 'react-dom/server';
import slugify from 'slugify';
import { DateTime } from 'luxon';

import { ImagingResult, ImagingResultAttachment } from '@/@types';
import { AttachmentViewer, BasicCard, ImagingResultDetailsCard, Loading } from '@/components';
import { useAuthContext, usePageContext } from '@/contexts';
import { usePatientImagingResults } from '@/hooks';

export function ImagingResultDetailsPage() {
  const { setIsBackButtonVisible, setActions } = usePageContext();
  const { patient } = useAuthContext();
  const { findPatientImagingResultsDetailsAttachment, findPatientImagingResultsDetails } =
    usePatientImagingResults();
  const imagingResultId = Number(useParams()?.id);
  const [isAttachmentLoading, setIsAttachmentLoading] = useState(false);
  const [attachmentData, setAttachmentData] = useState<ImagingResultAttachment[]>([]);
  const [imagingResult, setImagingResult] = useState<ImagingResult>();

  useEffect(() => {
    setIsBackButtonVisible(true, 'Imaging Results');
  }, [setIsBackButtonVisible]);

  const findImagingResultAttachment = useCallback(async () => {
    if (!patient?.id) return;
    const attachment = await findPatientImagingResultsDetailsAttachment(
      patient.id,
      imagingResultId
    );
    if (attachment) {
      setAttachmentData([attachment]);
      return true;
    }
  }, [findPatientImagingResultsDetailsAttachment, imagingResultId, patient?.id]);

  useEffect(() => {
    async function loadInitialData() {
      if (!patient) return;
      const details = await findPatientImagingResultsDetails(patient.id, imagingResultId);
      setImagingResult(details);
      try {
        setIsAttachmentLoading(true);
        await findImagingResultAttachment();
      } finally {
        setIsAttachmentLoading(false);
      }
    }
    loadInitialData();
  }, [
    patient,
    imagingResultId,
    findPatientImagingResultsDetails,
    findPatientImagingResultsDetailsAttachment,
    findImagingResultAttachment,
  ]);

  const exportContent = useMemo(() => {
    if (!imagingResult) {
      return;
    }

    return (
      <div className='bg-white p-[var(--space-regular)]'>
        <div className='flex items-center mb-[var(--space-lg)]'>
          <div className='grid grid-cols-2 flex-1'>
            <div className='flex flex-col'>
              <div className='py-[var(--space-xs)]'>
                <span className='font-medium pr-[var(--space-xxs)]'>Name:</span>
                <span>
                  {patient?.firstName} {patient?.lastName}
                </span>
              </div>
              <div>
                <span className='font-medium pr-[var(--space-xxs)]'>Date of birth:</span>
                <span>{patient?.dateOfBirth}</span>
              </div>
            </div>

            <div className='flex flex-col ml-[var(--space-xs)]'>
              <div className='py-[var(--space-xs)]'>
                <span className='font-medium pr-[var(--space-xxs)]'>Exam:</span>
                <span>{imagingResult.description}</span>
              </div>
              <div>
                <span className='font-medium pr-[var(--space-xxs)]'>Date:</span>
                <span>
                  {imagingResult.observationDateTime
                    ? DateTime.fromISO(imagingResult.observationDateTime).toFormat('LLLL d, y')
                    : DateTime.fromISO(imagingResult.createdDateTime).toFormat('LLLL d, y')}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='border-2 border-[var(--gray-2)]'>
          <div
            className='w-full md:min-w-[500px] p-5 rounded-lg text-kelp text-sm leading-6'
            dangerouslySetInnerHTML={{ __html: imagingResult.documentData }}
          ></div>
        </div>
      </div>
    );
  }, [imagingResult, patient?.firstName, patient?.lastName, patient?.dateOfBirth]);

  const generatePdf = useCallback(async () => {
    const pdf = new jsPDF('p', 'px', 'a4');
    pdf.html(renderToStaticMarkup(exportContent), {
      callback: function (doc) {
        doc.save(`${slugify(imagingResult?.description as string)}-${Date.now()}.pdf`);
      },
      x: 0,
      y: 0,
      width: 450,
      windowWidth: 800,
      autoPaging: 'text',
    });
  }, [exportContent, imagingResult?.description]);

  useEffect(() => {
    if (imagingResult && imagingResult.documentData) {
      setActions({
        buttons: [
          {
            icon: 'download',
            label: 'Download',
            onClick: () => generatePdf(),
          },
        ],
      });
    }
  }, [generatePdf, imagingResult, setActions]);

  return (
    <div className='mt-5'>
      {imagingResult && <ImagingResultDetailsCard imagingResult={imagingResult} />}
      {isAttachmentLoading ? (
        <BasicCard title='Attachments'>
          <Loading label='Loading attachments...' />
        </BasicCard>
      ) : (
        attachmentData?.length > 0 && (
          <BasicCard title='Attachments' className='mt-5'>
            <div className='flex items-start justify-between flex-wrap gap-4'>
              <AttachmentViewer attachments={attachmentData} />
            </div>
          </BasicCard>
        )
      )}
    </div>
  );
}
