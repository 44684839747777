import { Tooltip } from 'primereact/tooltip';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { CustomTableHeader, ImagingResultCardItem } from '@/@types';
import { CustomTable, RadiologyIcon } from '..';

type ImagingResultsCardProps = {
  results: ImagingResultCardItem[];
};

export function ImagingResultsCard({ results }: ImagingResultsCardProps) {
  const navigate = useNavigate();
  const tableHeaders: CustomTableHeader[] = [
    {
      key: 'description',
      label: 'Exam',
    },
    {
      key: 'provider',
      label: 'Ordering Clinician',
      width: 200,
    },
    {
      key: 'date',
      label: 'Date',
      width: 300,
    },
  ];

  const handleOnClickItem = useCallback(
    (item: ImagingResultCardItem) => {
      navigate(`/results/imaging/${item.id}`);
    },
    [navigate]
  );

  const items = useMemo(
    () =>
      results.map((result) => ({
        ...result,
        description: (
          <div className='flex gap-2'>
            <span>{result.description}</span>
            <span className='h-[21px] flex items-center gap-1'>
              {result.pacsLink &&
                result.pacsLink.map(({ href }) => (
                  <>
                    <Tooltip target='.pacs-link-tooltip' className='text-xs rounded-lg' />
                    <button
                      key={href}
                      data-pr-tooltip='Open image in Ambra'
                      data-pr-position='right'
                      className='pacs-link-tooltip rounded-lg cursor-pointer px-2 hover:bg-gray-200 active:bg-gray-300'
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(href, '_blank');
                      }}
                    >
                      <RadiologyIcon />
                    </button>
                  </>
                ))}
            </span>
          </div>
        ),
        onClick: () => handleOnClickItem(result),
      })),
    [handleOnClickItem, results]
  );

  return (
    <CustomTable
      emptyMessage='No imaging results'
      sortable={['description', 'provider', 'date']}
      headers={tableHeaders}
      items={items}
    />
  );
}
