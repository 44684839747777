import { Doctor } from './';

type SelectOption = { id: number; label: string };

export type Appointment = {
  id: number;
  patientId: number;
  appointmentId: number;
  date: string;
  end: string;
  description?: string | null;
  title: string;
  type: string;
  typeId: number;
  resourceId: number;
  statusid: any;
  patientName: string;
  duration: number;
  firstname: string;
  lastname: string;
  atriaAppointment: boolean;
  providerId: number;
  providerName: string;
  additionalProviders: SelectOption[];
  rooms: { id: string | number; label: string }[];
  confirmed: boolean;
  checkIn: string;
  checkOut: string;
  encounterState?: 'OPEN' | 'CLOSED';
  encounterId?: number;
  appointmentStatus: string;
  hasLetter: boolean;
  doctor?: Doctor;
  conferenceLink?: string;
  conferenceId?: string;
  conferencePassword?: string;
};
export namespace Appointments {
  export type UpcomingAppointment = {
    appointments: Appointment[];
    key: string;
    date: string;
    endDate: string;
    startDate: string;
    doctor?: Doctor;
    patientPhone?: string;
    address?: string;
    confirmed: boolean;
    location: 'New York' | 'Palm Beach' | 'Telehealth' | 'Home' | 'Phone';
  };

  export type Response = UpcomingAppointment[];
}

export type AppointmentCard = {
  id: number;
  appointmentId: number;
  start: string;
  providerName: string;
  title: string;
  hasEncounter: boolean;
  hasLetter: boolean;
  encounterId?: number;
};

export type AppointmentEncounterSummary = {
  summaryHtml: string;
};

export type AppointmentLetter = {
  summaryJSON?: LetterJSON;
  summaryHtml: string;
  attachmentsDocumentId?: number;
  appointment: {
    title: string;
    date: string;
  };
};

type LetterJSON = {
  patient: {
    id: string;
    firstName: string;
    middleName?: string;
    lastName: string;
    age: string;
    sex: string;
    dob: string;
    address: {
      address1: string;
      city: string;
      state: string;
      zip: string;
    };
    phone: {
      home: string;
      work?: string;
    };
  };
  encounter: Partial<{
    reason: string;
    info: string;
    historyOfPresentIllness: string;
    reviewOfSystems: string;
    vitals?: { [key: string]: any }[];
    physicalExam: string;
    assessmentAndPlan: string;
    discussionNotes?: string;
  }>;
  provider?: Partial<{
    signature: string;
    name: string;
  }>;
};

export enum AppointmentSelectionType {
  UPCOMING,
  PAST,
}
