import { useApplicationContext, useAuthContext } from '@/contexts';
import { useEffect, useMemo, useState } from 'react';
import { CmoCard } from '../card';
import { CareTeamCard } from '../careTeam';
import { CareTeamProviderList } from '../careTeamProviders';
import { CareTeamLoading } from '../loading';

type MyTeamLoadingProps = {
  isLoading: boolean;
  children: React.ReactNode;
};

const MyTeamLoading = ({ isLoading, children }: MyTeamLoadingProps) => {
  return isLoading ? <CareTeamLoading /> : <>{children}</>;
};

export function MyTeam() {
  const { getDoctor, doctor, getSpecialists, providers } = useApplicationContext();
  const { patient } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadInitialData = async () => {
      setIsLoading(true);
      try {
        if (patient?.primaryProvider) await getDoctor(patient.primaryProvider.id.toString());
        await getSpecialists();
      } finally {
        setIsLoading(false);
      }
    };
    loadInitialData();
  }, [getDoctor, getSpecialists, patient?.primaryProvider]);

  const providersList = useMemo(() => {
    if (!providers) return [];

    const providersFormatted = providers
      .map((provider) => ({
        id: provider.athenaProvider,
        imageURL: provider.photo,
        name: provider.name,
        locations: provider.location,
        profession: provider.degree,
        title: provider.title,
        contact: provider.cmoNumber,
        isYourCMO: patient?.primaryProvider?.id === Number(provider.athenaProvider),
        doctor: provider,
      }))
      .filter((provider) => !provider.isYourCMO)
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    return providersFormatted;
  }, [patient?.primaryProvider?.id, providers]);

  const hasCareTeam = useMemo(
    () => doctor?.pods.podMembers && doctor.pods.podMembers.length,
    [doctor?.pods.podMembers]
  );

  return (
    <div className='pt-9'>
      <MyTeamLoading isLoading={isLoading}>
        {doctor && <CmoCard doctor={doctor} />}
        <div className='space-y-5 mt-5'>
          {hasCareTeam && <CareTeamCard />}
          <CareTeamProviderList
            isSidebarVisible={false}
            data={[
              {
                speciality: 'Your specialists',
                providers: providersList,
              },
            ]}
          />
        </div>
      </MyTeamLoading>
    </div>
  );
}
