import { Appointment, Appointments, Doctor } from '@/@types';
import { Location, locations } from '@/config/locationsData';
import { useAuthContext } from '@/contexts';
import { StringHelper } from '@lib-atria/ui-toolkit';
import {
  AppointmentsCardHomeVisit,
  AppointmentsCardLocation,
  AppointmentsCardPhoneCall,
  AppointmentsCardTelehealth,
} from '../appointmentsCard';

export function UpcomingAppointmentsLocation({
  appointmentGroup,
}: {
  appointmentGroup: Appointments.UpcomingAppointment;
}) {
  const location = StringHelper.firstLetterUppercase(appointmentGroup.location);
  const { patient } = useAuthContext();

  switch (location) {
    case 'New York':
      return <LocationCard location={locations[0]} />;
    case 'Palm Beach':
      return <LocationCard location={locations[1]} />;
    case 'Telehealth':
      return (
        <TelehealthCard
          appointments={appointmentGroup.appointments}
          cmoEmail={patient?.cmoEmail || ''}
        />
      );
    case 'Home':
      return (
        <HomeVisitCard
          appointments={appointmentGroup.appointments}
          address={appointmentGroup.address}
          doctor={appointmentGroup.doctor}
          cmoEmail={patient?.cmoEmail || ''}
        />
      );
    case 'Phone':
      return (
        <PhoneCallCard
          appointments={appointmentGroup.appointments}
          phoneNumber={appointmentGroup.patientPhone || ''}
          cmoEmail={patient?.cmoEmail || ''}
        />
      );
    default:
      return null;
  }
}

const TelehealthCard = ({
  appointments,
  cmoEmail,
}: {
  appointments: Appointment[];
  cmoEmail: string;
}) => (
  <>
    {appointments.map(
      (appt) =>
        appt.conferenceLink && (
          <AppointmentsCardTelehealth
            key={appt.id}
            meetingId={appt.conferenceId || ''}
            videoLink={appt.conferenceLink}
            phoneNumber={appt.doctor?.cmoNumber}
            emailCmo={cmoEmail}
            passcode={appt.conferencePassword!}
          />
        )
    )}
  </>
);

const HomeVisitCard = ({
  appointments,
  address,
  doctor,
  cmoEmail,
}: {
  appointments: Appointment[];
  address?: string;
  doctor?: Doctor;
  cmoEmail: string;
}) => (
  <>
    {appointments.map((appt) => (
      <AppointmentsCardHomeVisit
        key={appt.id}
        address={address}
        emailCmo={cmoEmail}
        phoneNumber={doctor?.cmoNumber}
      />
    ))}
  </>
);

const PhoneCallCard = ({
  appointments,
  phoneNumber,
  cmoEmail,
}: {
  appointments: Appointment[];
  phoneNumber: string;
  cmoEmail: string;
}) => (
  <>
    {appointments.map((appt) => (
      <AppointmentsCardPhoneCall key={appt.id} phoneNumber={phoneNumber} emailCmo={cmoEmail} />
    ))}
  </>
);

const LocationCard = ({ location }: { location: Location }) => (
  <AppointmentsCardLocation location={location} />
);
