import { Auth } from '@/@types';
import { useAuthContext, useContactDialogContext } from '@/contexts';
import { eventAnalytics } from '@/providers';
import { Button } from '@lib-atria/ui-toolkit';
import { useCallback, useMemo } from 'react';

type Props = {
  label: string;
  topic: string;
  icon: string;
};

export function ContactButton({ label, topic, icon }: Props) {
  const { patient, user } = useAuthContext();
  const {
    isContactDialogVisible,
    setContactDialogVisibility,
    setContactDialogTopic,
    contactDialogTopic,
    resetContactDialog,
  } = useContactDialogContext();

  const handleContactYourCMOButton = useCallback(() => {
    eventAnalytics.track('ContactYourCMO', { cmoEmail: patient?.cmoEmail });
    resetContactDialog();
    setContactDialogTopic(topic || contactDialogTopic);
    setContactDialogVisibility(!isContactDialogVisible);
  }, [
    contactDialogTopic,
    isContactDialogVisible,
    patient?.cmoEmail,
    resetContactDialog,
    setContactDialogTopic,
    setContactDialogVisibility,
    topic,
  ]);

  const hasPermissionToContactCareTeam = useMemo(() => {
    if (user?.isAdmin) {
      return true;
    }
    return user?.permissions?.includes(Auth.Permissions.UserPermissions.CONTACT_CARE_TEAM);
  }, [user?.isAdmin, user?.permissions]);

  if (!hasPermissionToContactCareTeam) {
    return <></>;
  }

  return (
    <Button
      variant='tertiary'
      size='medium'
      icon={icon}
      iconVisibility='mobile-only'
      textVisibility='desktop-only'
      label={label}
      onClick={handleContactYourCMOButton}
    />
  );
}
