import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import { CustomTableHeader, LabResultCardItem } from '@/@types';
import { useAuthContext, useContactDialogContext, useToastContext } from '@/contexts';
import { usePatientLabResults } from '@/hooks';
import { CustomTable } from '..';

type LabResultsCardProps = {
  results: LabResultCardItem[];
};

const tableHeaders: CustomTableHeader[] = [
  {
    key: 'description',
    label: 'Test',
  },
  {
    key: 'provider',
    label: 'Ordering Clinician',
    width: 300,
  },
  {
    key: 'observationLength',
    label: 'Results',
    width: 150,
  },
  {
    key: 'date',
    label: 'Date',
    width: 200,
  },
];

export function LabResultsCard({ results }: LabResultsCardProps) {
  const navigate = useNavigate();
  const { patient } = useAuthContext();
  const { toast } = useToastContext();
  const { downloadPatientLabResult } = usePatientLabResults();
  const { contactDialogTopic, setContactDialogVisibility, setContactDialogTopic } =
    useContactDialogContext();

  const handleOnClickItem = useCallback(
    (item: LabResultCardItem) => {
      navigate(`/results/lab/${item.id}`);
    },
    [navigate]
  );

  const findLabResultAttachment = useCallback(
    async (item: LabResultCardItem) => {
      if (!patient?.id) return;

      try {
        const response = await downloadPatientLabResult(patient.id, item.id);

        const url = window.URL.createObjectURL(response!.blob!);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${item.description}_${DateTime.fromFormat(item.date, 'LLL dd, yyyy').toFormat('yyyy_LL_dd')}.${response?.type}`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode!.removeChild(link);
      } catch {
        toast?.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to download file',
          life: 2500,
        });
      }
    },
    [downloadPatientLabResult, patient, toast]
  );

  const handleShowContactCareTeamDialog = useCallback(
    (item: LabResultCardItem) => {
      setContactDialogVisibility(true);
      setContactDialogTopic(
        contactDialogTopic.length > 0
          ? `${contactDialogTopic} and '${item.description}'`
          : `'${item.description}'`
      );
    },
    [setContactDialogVisibility, setContactDialogTopic, contactDialogTopic]
  );

  return (
    <>
      <CustomTable
        headers={tableHeaders}
        items={results.map((result) => ({
          ...result,
          onClick: () => handleOnClickItem(result),
          key: result.id,
          actions: {
            more: [
              {
                label: 'View',
                onClick: () => handleOnClickItem(result),
              },
              {
                label: 'Download',
                onClick: () => findLabResultAttachment(result),
              },
              {
                label: 'Contact care team',
                onClick: () => handleShowContactCareTeamDialog(result),
              },
            ],
          },
        }))}
      />
    </>
  );
}
