import { useEffect, useMemo, useState } from 'react';

import { HomeCards, HomeContentLoading } from '@/components';
import { useApplicationContext, useAuthContext, usePageContext } from '@/contexts';

export function HomePage() {
  const { patient } = useAuthContext();
  const { dashboardSummary, findDashboardSummary } = useApplicationContext();
  const { setPageTitle } = usePageContext();
  const [isLoading, setIsLoading] = useState(false);

  const greetings = useMemo(() => {
    const hours = new Date().getHours();
    if (hours < 12) {
      return 'Good morning';
    } else if (hours < 17) {
      return 'Good afternoon';
    }
    return 'Good evening';
  }, []);

  useEffect(() => {
    setPageTitle('Home');
  }, [setPageTitle]);

  useEffect(() => {
    async function init() {
      setIsLoading(true);

      await findDashboardSummary();
      setIsLoading(false);
    }

    if (!dashboardSummary) {
      init();
    }
  }, [dashboardSummary, findDashboardSummary]);

  return (
    <>
      <div className='flex flex-row items-start flex-wrap gap-4 justify-between pb-6 lg:pb-0 border-b border-kelp mb-6'>
        <h1 className='pt-10 font-display font-medium text-kelp text-[4rem] leading-tight'>
          {greetings}, {patient?.firstNameUsed || patient?.firstName}
        </h1>
      </div>
      {isLoading ? <HomeContentLoading /> : <HomeCards summary={dashboardSummary} />}
    </>
  );
}
